export const TYPE_DROPDOWN = "dorpdown";
export const TYPE_DATEPICKER = "datepicker";
export const TYPE_AGE_PICKER = "agepicker";
export const TYPE_AGE_RANGE_PICKER = "agerangepicker";
export const TYPE_CORPORATE = "typecorporate";
export const TYPE_DATE_RANGE_PICKER = "date_range_picker";
export const TYPE_TEXTBOX = "TYPE_TEXTBOX";
export const TYPE_DOCTOR_DROPDOWN = "TYPE_DOCTOR_DROPDOWN";
export const TYPE_MULTI_DROPDOWN = "TYPE_MULTI_DROPDOWN";
export const TYPE_DISCOUNT_RULES = "TYPE_DISCOUNT_RULES";
export const TYPE_SESSION_PICKER = "TYPE_SESSION_PICKER";
export const TYPE_HEALTH_CONDITION = "TYPE_HEALTH_CONDITION";
export const TYPE_SEARCH_OUTCOME = "TYPE_SEARCH_OUTCOME";

function* range(start, end, step) {
  while (start < end) {
    yield start;
    start += step;
  }
}

export const ageArray = Array.from(range(1, 100, 1));
// const ageArray = Array.from(Array(100).keys())
export const dynamicQuestionnaireListFilter = [
  // {
  //   filter_title: "Submitted Date",
  //   filter_key: "uploaded_date",
  //   element_type: TYPE_DATEPICKER,
  //   filter_values: null,
  // },
  {
    filter_title: "Questionnaire Status",
    filter_key: "questionnaire_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Pending", value: "pending" },
      { lable: "CS Reviewed", value: "cs_reviewed" },
      { lable: "Doctor Reviewed", value: "doctor_reviewed" },
      // {lable: "Disabled", value: "disabled"},
    ],
  },
  {
    filter_title: "Created At",
    filter_key: "created_at",
    element_type: TYPE_DATEPICKER,
    // params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Reviewed Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  // {
  //   filter_title: "Questionnaires Type",
  //   filter_key: "questionnaire_type",
  //   element_type: TYPE_DROPDOWN,
  //   params: null,
  //   filter_values: [
  //     {lable: "PCR", value: "pcr"},
  //     {lable: "Anti Body", value: "anti_body"},
  //   ],
  // },
  // {
  //   filter_title: "Patient Gender",
  //   filter_key: "patient_gender",
  //   element_type: TYPE_DROPDOWN,
  //   params: null,

  //   filter_values: [
  //     {lable: "Male", value: "male"},
  //     {lable: "Female", value: "female"},
  //   ],
  // },

  // {
  // 	filter_title: 'Corporate',
  // 	filter_key: 'corporate_id',
  // 	element_type: TYPE_CORPORATE,
  // 	params: null,
  // },
  // {
  //   filter_title: "Patient DOB",
  //   filter_key: "patient_dob",
  //   element_type: TYPE_DATEPICKER,
  //   // params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_type: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];
export const upcomingApptFilters = [
  {
    filter_title: "Date",
    filter_key: "scheduled_date",
    element_type: TYPE_DATEPICKER,
    isOnlySelected: true,
    filter_values: null,
  },
  {
    filter_title: "Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Appointment Type",
    filter_key: "offline_appointment",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Face-2-Face", value: true },
      { lable: "Video Call", value: false },
    ],
  },
  {
    filter_title: "Appointment Status",
    filter_key: "status",
    element_type: TYPE_MULTI_DROPDOWN,
    params: null,
    filter_values: [
      { name: "Ready For Call", id: "ready_for_call" },
      // { name: "Not Attended", id: "not_attended" },
      // { name: "On Call", id: "on_call" },
      // { name: "Finished", id: "finished" },
      // { name: "Cancelled", id: "cancelled" },
      { name: "Booked", id: "booked" },
      { name: "Patient Arrived", id: "patient_arrived" },
      { name: "Patient Not Arrived", id: "patient_not_arrived" },
    ],
  },
  {
    filter_title: "Patient Gender",
    filter_key: "patient_gender",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Male", value: "male" },
      { lable: "Female", value: "female" },
    ],
  },
  {
    filter_title: "Patient Status",
    filter_key: "patient_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Active", value: "active" },
      { lable: "Unverified", value: "unverified" },
      { lable: "Rejected", value: "rejected" },
      { lable: "Disabled", value: "disabled" },
    ],
  },
  {
    filter_title: "Corporate",
    filter_key: "corporate_id",
    element_type: TYPE_CORPORATE,
    params: null,
  },
  {
    filter_title: "Patient DOB",
    filter_key: "patient_dob",
    element_type: TYPE_DATEPICKER,
    isOnlySelected: true,
    filter_values: null,
  },
  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_t                                                                                                                                                                            ype: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   isOnlySelected: true,
  //   deselectDate: ["date_period[start_date]", "date_period[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];

export const ApprovedRejectApptFilters = [
  {
    filter_title: "Date",
    filter_key: "scheduled_date",
    element_type: TYPE_DATEPICKER,
    isOnlySelected: true,
    deselectDate: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Appointment Type",
    filter_key: "offline_appointment",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Face-2-Face", value: true },
      { lable: "Video Call", value: false },
    ],
  },
  // {
  //   filter_title: "Appointment Status",
  //   filter_key: "status",
  //   element_type: TYPE_MULTI_DROPDOWN,
  //   params: null,
  //   filter_values: [
  //     { name: "Ready For Call", id: "ready_for_call" },
  //     { name: "Not Attended", id: "not_attended" },
  //     { name: "On Call", id: "on_call" },
  //     { name: "Finished", id: "finished" },
  //     { name: "Cancelled", id: "cancelled" },
  //     { name: "Booked", id: "booked" },
  //     { name: "Patient Arrived", id: "patient_arrived" },
  //     { name: "Patient Not Arrived", id: "patient_not_arrived" },
  //   ],
  // },
  {
    filter_title: "Patient Gender",
    filter_key: "patient_gender",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Male", value: "male" },
      { lable: "Female", value: "female" },
    ],
  },
  {
    filter_title: "Patient Status",
    filter_key: "patient_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Active", value: "active" },
      { lable: "Unverified", value: "unverified" },
      { lable: "Rejected", value: "rejected" },
      { lable: "Disabled", value: "disabled" },
    ],
  },
  {
    filter_title: "Corporate",
    filter_key: "corporate_id",
    element_type: TYPE_CORPORATE,
    params: null,
  },
  {
    filter_title: "Patient DOB",
    filter_key: "patient_dob",
    element_type: TYPE_DATEPICKER,
    isOnlySelected: true,
    deselectDate: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_type: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   isOnlySelected: true,
  //   deselectDate: ["date_period[start_date]", "date_period[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];

export const previousFilter = [
  {
    filter_title: "Date",
    filter_key: "scheduled_date",
    element_type: TYPE_DATEPICKER,
    isOnlySelected: true,
    deselectDate: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Appointment Type",
    filter_key: "offline_appointment",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Face-2-Face", value: true },
      { lable: "Video Call", value: false },
    ],
  },
  {
    filter_title: "Appointment Status",
    filter_key: "status",
    element_type: TYPE_MULTI_DROPDOWN,
    params: null,
    filter_values: [
      // { name: "Ready For Call", id: "ready_for_call" },
      { name: "Not Attended", id: "not_attended" },
      // { name: "On Call", id: "on_call" },
      { name: "Finished", id: "finished" },
      { name: "Cancelled", id: "cancelled" },
      // { name: "Booked", id: "booked" },
      // { name: "Patient Arrived", id: "patient_arrived" },
      // { name: "Patient Not Arrived", id: "patient_not_arrived" },
    ],
  },
  {
    filter_title: "Patient Gender",
    filter_key: "patient_gender",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Male", value: "male" },
      { lable: "Female", value: "female" },
    ],
  },
  {
    filter_title: "Patient Status",
    filter_key: "patient_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Active", value: "active" },
      { lable: "Unverified", value: "unverified" },
      { lable: "Rejected", value: "rejected" },
      { lable: "Disabled", value: "disabled" },
    ],
  },
  {
    filter_title: "Corporate",
    filter_key: "corporate_id",
    element_type: TYPE_CORPORATE,
    params: null,
  },
  {
    filter_title: "Patient DOB",
    filter_key: "patient_dob",
    element_type: TYPE_DATEPICKER,
    isOnlySelected: true,
    deselectDate: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_type: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   isOnlySelected: true,
  //   deselectDate: ["date_period[start_date]", "date_period[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];
export const scriptReviewListFilter = [
  // {
  //   filter_title: "Drug Name",
  //   filter_key: "drug_name",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
  // {
  //   filter_title: "Assigned Date",
  //   filter_key: "doctor_assigned",
  //   element_type: TYPE_DATEPICKER,
  //   filter_values: null,
  // },
  {
    filter_title: "Ordered Date",
    filter_key: "created_at",
    element_type: TYPE_DATEPICKER,
    filter_values: null,
  },
  {
    filter_title: "Order Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Patient Gender",
    filter_key: "patient_gender",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Male", value: "male" },
      { lable: "Female", value: "female" },
    ],
  },
  {
    filter_title: "Patient Status",
    filter_key: "patient_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Active", value: "active" },
      { lable: "Unverified", value: "unverified" },
      { lable: "Rejected", value: "rejected" },
      { lable: "Disabled", value: "disabled" },
    ],
  },
  {
    filter_title: "Corporate",
    filter_key: "corporate_id",
    element_type: TYPE_CORPORATE,
    params: null,
  },
  {
    filter_title: "Patient DOB",
    filter_key: "patient_dob",
    element_type: TYPE_DATEPICKER,
    // params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
    filter_values: null,
  },

  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_type: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   isOnlySelected: true,
  //   deselectDate: ["date_period[start_date]", "date_period[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];

export const questionnaireListFilter = [
  {
    filter_title: "Submitted Date",
    filter_key: "uploaded_date",
    element_type: TYPE_DATEPICKER,
    filter_values: null,
  },
  {
    filter_title: "Submitted Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Questionnaires Type",
    filter_key: "questionnaire_type",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "PCR", value: "pcr" },
      { lable: "Anti Body", value: "anti_body" },
    ],
  },
  {
    filter_title: "Patient Gender",
    filter_key: "patient_gender",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Male", value: "male" },
      { lable: "Female", value: "female" },
    ],
  },
  {
    filter_title: "Patient Status",
    filter_key: "patient_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Active", value: "active" },
      { lable: "Unverified", value: "unverified" },
      { lable: "Rejected", value: "rejected" },
      { lable: "Disabled", value: "disabled" },
    ],
  },
  {
    filter_title: "Corporate",
    filter_key: "corporate_id",
    element_type: TYPE_CORPORATE,
    params: null,
  },
  {
    filter_title: "Patient DOB",
    filter_key: "patient_dob",
    element_type: TYPE_DATEPICKER,
    // params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
    filter_values: null,
  },
  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_type: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];

export const reportsViewListFilter = [
  {
    filter_title: "Result",
    filter_key: "result",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Positive", value: "positive" },
      { lable: "Negative", value: "negative" },
    ],
  },
  {
    filter_title: "Uploaded By",
    filter_key: "uploaded_by",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Patient", value: "patient" },
      { lable: "Customer Support", value: "customer_support" },
    ],
  },
  {
    filter_title: "Uploaded Date",
    filter_key: "uploaded_date",
    element_type: TYPE_DATEPICKER,
    filter_values: null,
  },
  {
    filter_title: "Uploaded Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Patient Gender",
    filter_key: "patient_gender",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Male", value: "male" },
      { lable: "Female", value: "female" },
    ],
  },
  {
    filter_title: "Patient Status",
    filter_key: "patient_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Active", value: "active" },
      { lable: "Unverified", value: "unverified" },
      { lable: "Rejected", value: "rejected" },
      { lable: "Disabled", value: "disabled" },
    ],
  },
  {
    filter_title: "Corporate",
    filter_key: "corporate_id",
    element_type: TYPE_CORPORATE,
    params: null,
  },
  {
    filter_title: "Patient DOB",
    filter_key: "patient_dob",
    element_type: TYPE_DATEPICKER,
    // params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
    filter_values: null,
  },
  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_type: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];
export const newReportsViewListFilter = [
  {
    filter_title: "Uploaded By",
    filter_key: "uploaded_by",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Patient", value: "patient" },
      { lable: "Customer Support", value: "customer_support" },
    ],
  },
  {
    filter_title: "Uploaded Date",
    filter_key: "uploaded_date",
    element_type: TYPE_DATEPICKER,
    filter_values: null,
  },
  {
    filter_title: "Uploaded Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Patient Gender",
    filter_key: "patient_gender",
    element_type: TYPE_DROPDOWN,
    params: null,

    filter_values: [
      { lable: "Male", value: "male" },
      { lable: "Female", value: "female" },
    ],
  },
  {
    filter_title: "Patient Status",
    filter_key: "patient_status",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Active", value: "active" },
      { lable: "Unverified", value: "unverified" },
      { lable: "Rejected", value: "rejected" },
      { lable: "Disabled", value: "disabled" },
    ],
  },
  {
    filter_title: "Corporate",
    filter_key: "corporate_id",
    element_type: TYPE_CORPORATE,
    params: null,
  },
  {
    filter_title: "Patient DOB",
    filter_key: "patient_dob",
    element_type: TYPE_DATEPICKER,
    // params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
    filter_values: null,
  },
  // {
  //   filter_title: "Patient DOB Range",
  //   filter_key: "date_of_birth",
  //   element_type: TYPE_DATE_RANGE_PICKER,
  //   params: ["date_of_birth[start_date]", "date_of_birth[end_date]"],
  //   filter_values: null,
  // },
  // {
  //   filter_title: "Post Code",
  //   filter_key: "search",
  //   element_type: TYPE_TEXTBOX,
  //   params: null,
  // },
];

export const preEncounterFilters = [
  // {
  // 	filter_title: 'Name',
  // 	filter_key: 'name',
  // 	element_type: TYPE_TEXTBOX,
  // 	params: null,
  // },
  {
    filter_title: "Session Date",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  // {
  // 	filter_title: 'Session Type',
  // 	filter_key: 'session_type',
  // 	element_type: TYPE_SESSION_PICKER,
  // },
  {
    filter_title: "Appointment Type",
    filter_key: "session_type",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Face-2-Face", value: "face_to_face" },
      { lable: "Video Call", value: "video" },
      { lable: "Phone Call", value: "phone_call" },
    ],
  },
];

export const preConsultationFiltersNotStartedPending = [
  {
    filter_title: "Health Conditions",
    filter_key: "health_conditions",
    element_type: TYPE_HEALTH_CONDITION,
    params: null,
  },
  {
    filter_title: "Created At",
    filter_key: "created_at",
    element_type: TYPE_DATEPICKER,
    // params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  // {
  //   filter_title: "Search Outcome",
  //   filter_key: "search_outcome",
  //   element_type: TYPE_SEARCH_OUTCOME,
  //   filter_values: null,
  // },
];

export const preConsultationFiltersInReviewReviewed = [
  {
    filter_title: "Health Conditions",
    filter_key: "health_conditions",
    element_type: TYPE_HEALTH_CONDITION,
    params: null,
  },
  {
    filter_title: "Search Outcome",
    filter_key: "search_outcome",
    element_type: TYPE_SEARCH_OUTCOME,
    filter_values: null,
  },
  {
    filter_title: "Created At",
    filter_key: "created_at",
    element_type: TYPE_DATEPICKER,
    // params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
  {
    filter_title: "Date Period",
    filter_key: "date_period",
    element_type: TYPE_DATE_RANGE_PICKER,
    params: ["date_period[start_date]", "date_period[end_date]"],
    filter_values: null,
  },
];

export const kioskPreConsultationFilters = [
  {
    filter_title: "Blood Pressure Ranges",
    filter_key: "bp_ranges",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Normal", value: "normal" },
      { lable: "Low BP", value: "low" },
      { lable: "Pre-Hypertension", value: "at_risk" },
      { lable: "Hypertension Stage 1", value: "high" },
      { lable: "Hypertension Stage 2", value: "very_high" },
      { lable: "Hypertension Stage 3", value: "severe" },
    ],
  },
  // "normal", "high", "at_risk", "low", "severe", "very_high"
  {
    filter_title: "Hypertensive",
    filter_key: "hypertensive",
    element_type: TYPE_DROPDOWN,
    params: null,
    filter_values: [
      { lable: "Yes", value: "yes" },
      { lable: "No", value: "no" },
    ],
  },
];
