/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Checkbox,
  Divider,
  Select,
  Spin,
  InputNumber,
  Tooltip,
  Radio,
  Input,
} from "antd";
import QuestionnaireFillUpItem from "containers/WalkinAppointment/QuestionnaireFillUpItem";
import { ValidateFillUpAnswer } from "containers/WalkinAppointment/ValidateFillupAnswers";
import { isArray, map } from "lodash";
import {
  fetchHyperTensiveQuestionnaire,
  fetchPreConsultQuestionnaire,
  submitKioskPreConsultQuestionnaire,
  submitPreConsultQuestionnaire,
} from "services/preConsultConditions";
import {
  errorToast,
  getErrorObject,
  renderHealthAssessmentInputColor,
  verifyObject,
} from "utilities/utils";
import "./questionnaire.css";
import AnswerReview from "./AnswerReview";
import isEmpty from "lodash/isEmpty";
import { SPLIT_SYMBOL } from "constants/common";
import CommonLoader from "components/common/CommonLoader";
import store from "app/store";
import { getQuestionnaireTemplatesApi } from "services/notifications";
import TemplateDropDown from "./TemplatesDropDown";
import { bpQuestions } from "./BloodPressureQuestionnaire";
const { Panel } = Collapse;
export default function PreConsultQuestionnaire({
  gender,
  healthConditionId,
  patiendId,
  getOutcome,
  healthConditionName,
  healthConditionSnomedCode,
  passedToken,
  selectedItem,
  questionnaireIndexes,
  corporate_id,
  isFromPreConsultListing,
  questionnaire,
  fromReviewPreConsult,
  setState,
  state,
  preConsultStatus,
  isShowBookingAppt,
  PassedBookAppointmentReuse,
  hypertensive,
  systolic_bp,
  diastolic_bp,
  pulse_per_minute,
  screened_bp_values,
  health_scan_log_id,
  expandClicked,
  selectedService,
  setIsEditModeState,
  setNextQuestionnaireIdForParent
}) {
  const [activeQuestionnaire, setActiveQuestionnaire] = useState(0);
  const [questionnaireDetails, setQuestionnaireDetails] = useState(null);
  const [outCome, setOutCome] = useState(null);
  const [finalOutCome, setFinalOutCome] = useState(null);
  const [toBePassedToken, setToBePassedToken] = useState(null);
  const [isGateWayPoint, setIsGatwayPoint] = useState(false);
  const [happy_to_review_submit, setHappyToReviewSubmit] = useState(false);
  const [answerReviewArray, setAnswerReviewArray] = useState([]);
  const [pointerIndex, setPointerIndex] = useState(0);
  const [finalStep, setFinalStep] = useState(false);
  const [count, setCount] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [latestAnswerId, setLatestAnswerId] = useState(false);
  const [fetchingQuestionnaire, setFetchingQuestionnaire] = useState(false);
  const [isQuestionnaireSubmitted, setIsQuestionnaireSubmitted] =
    useState(false);
  const [isSubmittingQuestionnaire, setSubmittingQuestionnaire] =
    useState(false);
  const [is_saftey_netting, setIsSafetyNetting] = useState(false);
  const [template_id, setTemplates] = useState(null);
  const [outcomeComment, setOutComeComment] = useState(null);
  const [comment_input, setcommentInput] = useState("");
  const [intialQuestionnaire, setInitialQuestionnaire] = useState(true);
  const [nextQuestionnaireID, setNextQuestionnaireID] = useState(true);
  const [fromAnswerView, setFromAnswerView] = useState(false);
  const [latestBpValues, setLatestBpValues] = useState({
    systolic_bp: null,
    diastolic_bp: null,
    pulse_per_minute: null,
  });

  const [hyperTesiveAnswer, setHyperTensiveAnswer] = useState(null);
  const [fittedAbpmAnswer, setFittedAbpmAnswer] = useState(null);
  const [serviceName, setServiceName] = useState(null);

  console.log("fromReviewPreConsult", fromReviewPreConsult);

  useEffect(() => {
    if (questionnaire) {
      setQuestionnaireDetails(questionnaire);
      setAnswerReviewArray(questionnaire);
    }
  }, [questionnaire]);

  useEffect(() => {
    console.log("passedToken ---- >", passedToken, selectedItem);
    if (passedToken) {
      console.log(" passedToken", passedToken);
      setToBePassedToken(passedToken);
    }
  }, [passedToken]);

  useEffect(() => {
    console.log("healthConditionId", healthConditionId);
    if (
      healthConditionId &&
      isFromPreConsultListing &&
      preConsultStatus !== "kiosk_pending" &&
      preConsultStatus !== "kiosk_reviewed"
    ) {
      // if (isShowBookingAppt === null || isShowBookingAppt === "true") {
      _fetchPreConsultQuestionnaire();
      // }
    }
  }, [healthConditionId, isFromPreConsultListing]);

  useEffect(() => {
    if (health_scan_log_id) {
      // if (isShowBookingAppt === null || isShowBookingAppt === "true") {
      _fetchHypertensiveQuestionnaire();
      // }
    }
  }, [health_scan_log_id]);

  // useEffect(() => {
  //   if (preConsultStatus === "kiosk_pending") {
  //     //  let dd= bpQuestions
  //     //  dd[0]?.question_details[0]?.pre_consult_answer?.actual_answer =['No']
  //     setQuestionnaireDetails(() => {
  //       let dd = bpQuestions;
  //       let ans =
  //         hypertensive === "yes" ? "Yes" : hypertensive === "no" ? "No" : null;
  //       dd[0].question_details[0].answer = ans;
  //       // dd[0].question_details[0].answer = ans;

  //       return dd;
  //     });
  //     setAnswerReviewArray(() => {
  //       let dd = bpQuestions;
  //       console.log("bpQuestions", bpQuestions);
  //       dd[0].question_details.map((v, index) => {
  //         let j = v.pre_consult_answer;
  //         let ans =
  //           hypertensive === "yes"
  //             ? "Yes"
  //             : hypertensive === "no"
  //             ? "No"
  //             : null;
  //         if (index === 0) j.actual_answer = [ans];

  //         // j.actual_answer = [ans];
  //         return j;
  //       });
  //       return dd;
  //     });
  //     setNextQuestionnaireID(null);
  //     if (hypertensive !== null) {
  //       setFinalStep(true);
  //     }
  //   }
  // }, [preConsultStatus]);
  const handleCommentInputChange = (e) => {
    setcommentInput(e.target.value);
  };
  const _fetchPreConsultQuestionnaire = async (passedId) => {
    let healthScanLogParams = {};
    // alert(health_scan_log_id)
    if (health_scan_log_id) {
      healthScanLogParams = {
        health_scan_log_id: health_scan_log_id,
      };
    }
    try {
      let payload = {
        health_condition_id: healthConditionId,
        patient_id: patiendId,
        corporate_id: corporate_id,
        token: passedToken,
        ...healthScanLogParams,
      };
      setFetchingQuestionnaire(true);
      let response = await fetchPreConsultQuestionnaire(payload);
      if (response?.status === 200) {
        let preConsultQuestionnaire = verifyObject(response, "data.data", null);
        let next_questionnaire_id = verifyObject(
          response,
          "data.common.next_questionnaire_id",
          null
        );
        console.log("🚀 ~ const_fetchPreConsultQuestionnaire= ~ next_questionnaire_id:", next_questionnaire_id)

        let questionnaire_indexes = verifyObject(
          response,
          "data.common.questionnaire_indexes",
          null
        );
        let passedOutCome = verifyObject(
          response,
          "data.common.outcome_details",
          null
        );
        console.log("passed", passedOutCome);
        if (next_questionnaire_id === null) {
          setFromAnswerView(true);
          // setFinalOutCome(passedOutCome)
        }
        setOutCome(passedOutCome);
        let nextQuestionnaireIndex = preConsultQuestionnaire.findIndex(
          (d) => d.id === next_questionnaire_id
        );
        setNextQuestionnaireID(next_questionnaire_id);
        setNextQuestionnaireIdForParent(next_questionnaire_id)
        let newPreConsultQuestionnaire = preConsultQuestionnaire.map((d) => {
          d.question_details.map((e) => {
            e.answer = e.pre_consult_answer?.actual_answer[0]
              ? e.pre_consult_answer?.actual_answer[0]
              : e.pre_consult_answer?.actual_answer
              ? e.pre_consult_answer?.actual_answer
              : e.answer;
            if (e.category === "multi_choice") {
              // let an = [];
              let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
                (d) =>
                  `${d}${SPLIT_SYMBOL}${
                    e?.question_options?.find((v) => v.title === d)?.id
                  }`
              );

              e.chekedAnswer = checkedAnswers;
              e.answer = checkedAnswers;
            }
            if (e.category === "drop_down") {
              e.answer = map(e.pre_consult_answer?.actual_answer, "id", []);
            }
            e.children.map((f) => {
              f.answer = f.pre_consult_answer?.actual_answer[0]
                ? f.pre_consult_answer?.actual_answer[0]
                : f.pre_consult_answer?.actual_answer
                ? f.pre_consult_answer?.actual_answer
                : f.answer;

              if (f.category === "drop_down") {
                f.answer = map(f.pre_consult_answer?.actual_answer, "id", []);
              }
              if (f.category === "multi_choice") {
                // let an = [];
                let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
                  (d) =>
                    `${d}${SPLIT_SYMBOL}${
                      e?.question_options?.find((v) => v.title === d)?.id
                    }`
                );

                f.chekedAnswer = checkedAnswers;
                f.answer =
                  isArray(f.pre_consult_answer?.actual_answer) &&
                  f.pre_consult_answer?.actual_answer.length > 0
                    ? f.pre_consult_answer?.actual_answer
                    : f.answer;
              }

              f.children.map((g) => {
                g.answer = g.pre_consult_answer?.actual_answer[0]
                  ? g.pre_consult_answer?.actual_answer[0]
                  : g.pre_consult_answer?.actual_answer
                  ? g.pre_consult_answer?.actual_answer
                  : g.answer;
                return g;
              });
              return f;
            });
            return e;
          });
          return d;
        });

        console.log("passedNextQuestionnaireIndex", nextQuestionnaireIndex);
        console.log("newPreConsultQuestionnaire", newPreConsultQuestionnaire);
        console.log("questionnaire_indexes", questionnaire_indexes);

        if (nextQuestionnaireIndex > 0) {
          // const allQuestionnaireIndexes = Object.values(questionnaireIndexes);
          setActiveQuestionnaire(nextQuestionnaireIndex);
          setPointerIndex(nextQuestionnaireIndex);
          let allAnswers = [];

          Object.keys(questionnaire_indexes).map((key) => {
            let questionnaireId = questionnaire_indexes[key];
            let questionnaire = newPreConsultQuestionnaire.find(
              (d) => d.id === questionnaireId
            );
            allAnswers.push(questionnaire);
            return null;
          });
          setAnswerReviewArray(allAnswers);
        }
        if (nextQuestionnaireIndex === -1) {
          console.log("All Questionnaire Submited");
          let allAnswers = [];
          Object.keys(questionnaire_indexes).map((key) => {
            console.log("All Questionnaire key", key);
            let questionnaireId = questionnaire_indexes[key];
            let questionnaire = newPreConsultQuestionnaire.find(
              (d) => d.id === questionnaireId
            );
            console.log("Found Questionnaire", questionnaire);
            allAnswers.push(questionnaire);
            return null;
          });
          setAnswerReviewArray(allAnswers);
          // setQuestionnaireDetails(allAnswers);
          setFinalStep(true);
        }
        setQuestionnaireDetails(newPreConsultQuestionnaire);
        setFetchingQuestionnaire(false);
      }
    } catch (e) {
      let { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      setFetchingQuestionnaire(false);
      // setState({
      //   ...state,
      //   isFetching: false,
      //   count: state.count + 1,
      // });
    }
  };

  const _fetchHypertensiveQuestionnaire = async (passedId) => {
    try {
      let payload = {
        health_scan_log_id: health_scan_log_id,
      };
      setFetchingQuestionnaire(true);
      let response = await fetchHyperTensiveQuestionnaire(payload);
      if (response?.status === 200) {
        let preConsultQuestionnaire = verifyObject(response, "data.data", null);
        let newPreConsultQuestionnaire = preConsultQuestionnaire.map((d) => {
          d.question_details.map((e) => {
            e.answer = e.pre_consult_answer?.actual_answer[0]
              ? e.pre_consult_answer?.actual_answer[0]
              : e.pre_consult_answer?.actual_answer
              ? e.pre_consult_answer?.actual_answer
              : e.answer;
            if (e.category === "multi_choice") {
              // let an = [];
              let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
                (d) =>
                  `${d}${SPLIT_SYMBOL}${
                    e?.question_options?.find((v) => v.title === d)?.id
                  }`
              );

              e.chekedAnswer = checkedAnswers;
              console.log("AMMMMMM", checkedAnswers);
            }
            e.children.map((f) => {
              f.answer = f.pre_consult_answer?.actual_answer[0]
                ? f.pre_consult_answer?.actual_answer[0]
                : f.pre_consult_answer?.actual_answer
                ? f.pre_consult_answer?.actual_answer
                : f.answer;

              f.children.map((g) => {
                g.answer = g.pre_consult_answer?.actual_answer[0]
                  ? g.pre_consult_answer?.actual_answer[0]
                  : g.pre_consult_answer?.actual_answer
                  ? g.pre_consult_answer?.actual_answer
                  : g.answer;
                return g;
              });
              return f;
            });
            return e;
          });
          return d;
        });
        setAnswerReviewArray(newPreConsultQuestionnaire);
        const answers = map(
          newPreConsultQuestionnaire[0]?.question_details,
          "answer"
        );
        if (answers.some((d) => d === undefined || d === null)) {
          setFinalStep(false);
        } else {
          setFinalStep(true);
        }
        console.log(
          "🚀 ~ const_fetchHypertensiveQuestionnaire= ~ answers:",
          answers.some((d) => d === undefined || d === null)
        );
        setQuestionnaireDetails(newPreConsultQuestionnaire);
        setFetchingQuestionnaire(false);
        // setFromAnswerView(true);
        console.log(
          "🚀 ~ const_fetchHypertensiveQuestionnaire= ~ newPreConsultQuestionnaire:",
          newPreConsultQuestionnaire
        );
      }
    } catch (e) {
      let { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      setFetchingQuestionnaire(false);
    }
  };

  // useEffect(() => {
  // }, [questionnaireDetails]);

  // useEffect(() => {
  //   if (passedNextQuestionnaireIndex > 0 && questionnaireDetails) {
  //     // const allQuestionnaireIndexes = Object.values(questionnaireIndexes);
  //     setActiveQuestionnaire(passedNextQuestionnaireIndex);
  //     setPointerIndex(passedNextQuestionnaireIndex);
  //     let allAnswers = [];

  //     Object.keys(questionnaireIndexes).map((key) => {
  //       let questionnaireId = questionnaireIndexes[key];
  //       let questionnaire = questionnaireDetails.find(
  //         (d) => d.id === questionnaireId
  //       );
  //       allAnswers.push(questionnaire);
  //       return null;
  //     });
  //     setAnswerReviewArray(allAnswers);
  //     // setQuestionnaireDetails(allAnswers);
  //     //   // setAnswerReviewArray(questionnaire);
  //   }
  //   console.log("passedNextQuestionnaireIndex", passedNextQuestionnaireIndex);
  //   if (passedNextQuestionnaireIndex === -1 && questionnaireDetails) {
  //     setFinalStep(true);
  //     let allAnswers = [];
  //     Object.keys(questionnaireIndexes).map((key) => {
  //       let questionnaireId = questionnaireIndexes[key];
  //       let questionnaire = questionnaireDetails.find(
  //         (d) => d.id === questionnaireId
  //       );
  //       allAnswers.push(questionnaire);
  //       return null;
  //     });
  //     setAnswerReviewArray(allAnswers);
  //     console.log("All Answers", allAnswers);
  //     //  setAnswerReviewArray((answerReviewArr) => {
  //     //   let newArr = answerReviewArr;
  //     //   const updatedIndex = parseInt(pointerIndex + 1);
  //     //   let splicedArr = newArr.slice(0, updatedIndex);
  //     //   console.log("newArr", splicedArr);
  //     //   return splicedArr;
  //     // });
  //   }
  // }, [
  //   passedNextQuestionnaireIndex,
  //   questionnaire,
  //   passedToken,
  //   questionnaireDetails,
  // ]);

  //   const handleCollapsiable = (key) => {
  //     setActiveQuestionnaire(parseInt(key));
  //   };

  const handleAnswerChange = (val, question, childQue, childOfChild) => {
    console.log("parent", question);
    console.log("childQue", childQue);
    console.log("childOfChild", childOfChild);
    console.log("val-->", val);

    if (question && childQue && childOfChild) {
      console.log("childOfChild", childOfChild?.title);
      setQuestionnaireDetails((questionnaire) => {
        const questionsDetails =
          questionnaire[activeQuestionnaire].question_details;
        questionsDetails?.map((d) => {
          if (d.id === question.id) {
            d?.children.map((k) => {
              if (k.id === childQue.id) {
                k?.children?.map((j) => {
                  if (j.id === childOfChild.id) {
                    let d = k;
                    // let editedParams = {};
                    if (
                      !isEmpty(d.answer) &&
                      d.answer?.toString().trim() !== val?.toString().trim()
                    ) {
                      d.isEdited = true;
                    }
                    // if (state.isEditMode) {
                    //   d.isEdited = true;
                    // }
                    if (d.category === "date") {
                      d.error = null;
                      d.answer = val;
                    }
                    if (d.category === "file") {
                      const url = URL.createObjectURL(val);
                      d.imageURL = url;
                    }
                    if (d.category === "multi_choice") {
                      console.log("multi_choice value", val);
                      let Ids = map(val, (d) =>
                        parseInt(d.split(`${SPLIT_SYMBOL}`)[1], 10)
                      );
                      const isNoneFlagID = d.question_options?.find(
                        (d) => d.none_flag
                      );
                      if (!d.is_none_flag && Ids.includes(isNoneFlagID?.id)) {
                        d.chekedAnswer = [
                          `${isNoneFlagID?.title}${SPLIT_SYMBOL}${isNoneFlagID?.id}`,
                        ];
                        Ids = [];
                        d.is_none_flag = true;
                      } else {
                        console.log("No Options have none flag");
                        d.chekedAnswer = val.filter(
                          (d) =>
                            d.split(`${SPLIT_SYMBOL}`)[1] != isNoneFlagID?.id
                        );
                        d.is_none_flag = false;
                      }
                      d.error = null;
                      d.answer = map(
                        val,
                        (d) => d.split(`${SPLIT_SYMBOL}`)[0]
                      ).toString();
                      d.chekedAnswer = val;
                      d.optionId = d.split(`${SPLIT_SYMBOL}`)[1];
                    } else {
                      d.error = null;
                      d.answer = val;
                    }
                    // j.answer = val;
                    // j.error = null;
                  }
                  return j;
                });
              }
              return k;
            });
          }
          return d;
        });
        return questionsDetails;
      });
    } else if (question && childQue) {
      console.log("Child", childQue);
      setQuestionnaireDetails((questionnaire) => {
        let updatedQuestionaire = questionnaire.map((q, index) => {
          if (index !== activeQuestionnaire) return q;

          const updatedQuestionDetails = q.question_details.map((qd) => {
            if (qd.id !== question.id) return qd;

            const updatedChildren = qd.children.map((child) => {
              if (child.id !== childQue.id) return child;

              const updatedChild = { ...child };
              console.log("updatedChild", updatedChild);

              if (
                !isEmpty(updatedChild.answer) &&
                updatedChild.answer?.toString().trim() !==
                  val?.toString().trim()
              ) {
                updatedChild.isEdited = true;
              }
              updatedChild.is_child = true;
              updatedChild.parent_id = question.id;

              if (updatedChild.category === "date") {
                updatedChild.error = null;
                updatedChild.answer = val;
              } else if (updatedChild.category === "file") {
                updatedChild.imageURL = URL.createObjectURL(val);
                updatedChild.answer = val;
                updatedChild.error = null;
              } else if (updatedChild.category === "multi_choice") {
                console.log(
                  "child multi_choice value",
                  val.map((d) => d.split(SPLIT_SYMBOL)[0]).toString()
                );
                const d = updatedChild;
                let Ids = map(val, (d) =>
                  parseInt(d.split(`${SPLIT_SYMBOL}`)[1], 10)
                );
                const isNoneFlagID = d.question_options?.find(
                  (d) => d.none_flag
                );
                if (!d.is_none_flag && Ids.includes(isNoneFlagID?.id)) {
                  d.chekedAnswer = [
                    `${isNoneFlagID?.title}${SPLIT_SYMBOL}${isNoneFlagID?.id}`,
                  ];
                  Ids = [];
                  d.is_none_flag = true;
                } else {
                  console.log("No Options have none flag");
                  d.chekedAnswer = val.filter(
                    (d) => d.split(`${SPLIT_SYMBOL}`)[1] != isNoneFlagID?.id
                  );
                  d.is_none_flag = false;
                }
                updatedChild.error = null;
                updatedChild.answer = map(
                  val,
                  (d) => d.split(`${SPLIT_SYMBOL}`)[0]
                ).toString();
                updatedChild.chekedAnswer = val;
                updatedChild.optionId = map(
                  val,
                  (d) => d.split(`${SPLIT_SYMBOL}`)[1]
                ).toString();
              } else {
                updatedChild.error = null;
                updatedChild.answer = val;
              }

              return updatedChild;
            });

            return { ...qd, children: updatedChildren };
          });

          return { ...q, question_details: updatedQuestionDetails };
        });
        console.log("updatedQuestionaire", updatedQuestionaire);
        return updatedQuestionaire;
      });
    } else {
      setQuestionnaireDetails((questionnaireDetails) => {
        const updatedQuestionnaireDetails = questionnaireDetails.map(
          (questionnaire, index) => {
            if (index === activeQuestionnaire) {
              const updatedQuestionDetails = questionnaire.question_details.map(
                (detail) => {
                  if (detail.id === question.id) {
                    let d = detail;
                    // let editedParams = {};
                    console.log("D answer", d.answer);
                    console.log("D val", val);

                    if (
                      !isEmpty(d.answer) &&
                      d.answer?.toString().trim() !== val?.toString().trim()
                    ) {
                      d.isEdited = true;
                    }
                    // if (state.isEditMode) {
                    //   d.isEdited = true;
                    // }
                    if (d.category === "date") {
                      d.error = null;
                      d.answer = val;
                    }
                    // if (d.category === "drop_down") {
                    //   console.log(
                    //     "INNNN drop_down",
                    //     d.category,
                    //     val?.split(",")
                    //   );
                    //   d.error = null;
                    //   d.answer = val?.split(",");
                    //   return null;
                    // }
                    if (d.category === "file" && val instanceof Blob) {
                      const url = URL.createObjectURL(val);
                      d.imageURL = url;
                    }
                    if (d.category === "multi_choice") {
                      if (d.answer === undefined) {
                        d.isEdited = true;
                      }
                      console.log("multi_choice value", val);
                      let Ids = map(val, (d) =>
                        parseInt(d.split(`${SPLIT_SYMBOL}`)[1], 10)
                      );
                      const isNoneFlagID = d.question_options?.find(
                        (d) => d.none_flag
                      );
                      if (!d.is_none_flag && Ids.includes(isNoneFlagID?.id)) {
                        d.chekedAnswer = [
                          `${isNoneFlagID?.title}${SPLIT_SYMBOL}${isNoneFlagID?.id}`,
                        ];
                        Ids = [];
                        d.answer = [
                          `${isNoneFlagID?.title}${SPLIT_SYMBOL}${isNoneFlagID?.id}`,
                        ];
                        d.is_none_flag = true;
                      } else {
                        console.log("No Options have none flag");
                        d.chekedAnswer = val.filter(
                          (d) =>
                            d.split(`${SPLIT_SYMBOL}`)[1] != isNoneFlagID?.id
                        );
                        d.is_none_flag = false;
                        d.answer = map(
                          val,
                          (d) => d.split(`${SPLIT_SYMBOL}`)[0]
                        ).toString();
                      }
                      d.error = null;

                      d.optionId = map(
                        val,
                        (d) => d.split(`${SPLIT_SYMBOL}`)[1]
                      ).toString();
                      d.children.map((c) => {
                        console.log("CCCC", c);
                        if (c.is_conditional) {
                          if (c.conditional_text_values[0] !== val) {
                            c.answer = null;
                          }
                        }
                        return c;
                      });
                    }

                    if (d.category === "single_choice") {
                      d.error = null;
                      d.answer = val;
                      d.children.map((c) => {
                        console.log("SSSSS", c);
                        if (c.is_conditional) {
                          if (c.conditional_text_values[0] !== val) {
                            c.answer = null;
                            c.chekedAnswer = [];
                          }
                        }
                        return c;
                      });
                    } else {
                      d.error = null;
                      d.answer = val;
                    }

                    // return {
                    //   ...detail,
                    //   error: null,
                    //   answer: val,
                    //   ...editedParams,
                    // };
                  }
                  return detail;
                }
              );
              return {
                ...questionnaire,
                question_details: updatedQuestionDetails,
              };
            }
            return questionnaire;
          }
        );
        console.log("question", updatedQuestionnaireDetails);

        return updatedQuestionnaireDetails;
      });
    }
  };
  const handleErrors = (errMsg, question, childQue, childOfChild) => {
    if (question && childQue && childOfChild) {
      setQuestionnaireDetails((questionnaire) => {
        const questionsDetails =
          questionnaire[activeQuestionnaire].question_details;
        questionsDetails?.map((d) => {
          if (d.id === question.id) {
            d?.children.map((k) => {
              if (k.id === childQue.id) {
                k?.children?.map((j) => {
                  if (j.id === childOfChild.id) {
                    j.error = errMsg;
                  }
                  return j;
                });
              }
              return k;
            });
          }
          return d;
        });
        return questionsDetails;
      });
    } else if (question && childQue) {
      console.log("ERRR MESSAGE", errMsg);
      console.log("Child", childQue?.title);

      // setQuestionnaireDetails((questionnaire) => {
      //   const questionsDetails =
      //     questionnaire[activeQuestionnaire].question_details;
      //   questionsDetails?.map((d) => {
      //     if (d.id === question.id) {
      //       d.children.map((k) => {
      //         if (k.id === childQue.id) {
      //           k.error = errMsg;
      //         }
      //         return k;
      //       });
      //     }
      //     return d;
      //   });
      //   return { ...questionnaire, questionsDetails };
      // });
      let shallowedCopyQuestionnaire = questionnaireDetails;
      let updatedQuestionaire = shallowedCopyQuestionnaire.map((q, index) => {
        if (index !== activeQuestionnaire) return q;

        const updatedQuestionDetails = q.question_details.map((qd) => {
          if (qd.id !== question.id) return qd;

          const updatedChildren = qd.children.map((child) => {
            if (child.id !== childQue.id) return child;

            const updatedChild = { ...child };
            console.log("updatedChild", updatedChild);
            updatedChild.error = errMsg;
            updatedChild.answer = null;
            if (child.category === "file") {
              updatedChild.imageURL = null;
            }
            console.log("updatedChild in errors", updatedChild);
            return updatedChild;
          });

          return { ...qd, children: updatedChildren };
        });
        console.log("updatedQuestionDetails", updatedQuestionDetails);

        return { ...q, question_details: updatedQuestionDetails };
      });
      console.log("updatedQuestionaire", updatedQuestionaire);
      setQuestionnaireDetails(updatedQuestionaire);
    } else {
      console.log("No Questionnaire", questionnaireDetails);
      setQuestionnaireDetails((questionnaireDetails) => {
        const updatedQuestionnaireDetails = questionnaireDetails.map(
          (questionnaire, index) => {
            if (index === activeQuestionnaire) {
              const updatedQuestionDetails = questionnaire.question_details.map(
                (detail) => {
                  if (detail.id === question.id) {
                    return {
                      ...detail,
                      error: errMsg,
                      answer: null,
                    };
                  }
                  return detail;
                }
              );
              return {
                ...questionnaire,
                question_details: updatedQuestionDetails,
              };
            }
            return questionnaire;
          }
        );
        console.log("question", updatedQuestionnaireDetails);
        return updatedQuestionnaireDetails;
      });
    }
  };
  const handleSubmit = async () => {
    console.log("questionnaireDetails--", questionnaireDetails);

    const errors = ValidateFillUpAnswer(
      questionnaireDetails[activeQuestionnaire],
      handleErrors,
      gender
    );
    if (errors.isValid) {
      console.log("questionnaireDetails", questionnaireDetails);
      //   setActiveQuestionnaire(activeQuestionnaire + 1);
      setSubmittingQuestionnaire(true);
      try {
        let formData = new FormData();
        let questionnaireToBeSubmitted = [];

        questionnaireDetails[activeQuestionnaire]?.question_details?.map(
          (q) => {
            questionnaireToBeSubmitted.push(q, ...q.children);
            return null;
          }
        );
        formData.set(`user_id`, patiendId);
        formData.set(
          `questionnaire_template_id`,
          questionnaireDetails[activeQuestionnaire]?.id
        );
        if (toBePassedToken) {
          console.log("toBePassedToken", toBePassedToken);
          formData.set(`token`, toBePassedToken);
        }
        const questionnaireIsEdited = questionnaireDetails[
          activeQuestionnaire
        ]?.question_details?.filter((q) => q.isEdited === true);
        console.log(
          "🚀 ~ file: QuestionnaireFillUpTokenNew.jsx:462 ~ handleSubmitQuestionnaire ~ questionnaireIsEdited:",
          questionnaireIsEdited
        );
        // if (questionnaireIsEdited.length > 0 && isEditMode) {
        let tobePassedPatientAnswerId =
          questionnaireDetails[activeQuestionnaire]?.patient_answer_id;
        if (tobePassedPatientAnswerId) {
          formData.set(`patient_answer_id`, tobePassedPatientAnswerId);
        }
        // }
        // setQuestionnaireEdited(questionnaireIsEdited.length > 0 ? true : false);
        formData.set(`is_preconsult_questionnire`, true);
        formData.set("health_condition_id", healthConditionId);
        questionnaireToBeSubmitted
          .filter(
            (d) =>
              d.gender_preference === null ||
              d.gender_preference === gender ||
              d.gender_preference === "both"
          )
          .map((question, index) => {
            console.log("question index", question, index);
            if (question.answer) {
              formData.set(
                `answer_detail[${index}][question_detail_id]`,
                question.id
              );
              formData.set(`answer_detail[${index}][answer]`, question.answer);
              if (question.is_child && question.parent_id) {
                formData.set(`answer_detail[${index}][is_child]`, true);
                formData.set(
                  `answer_detail[${index}][parent_id]`,
                  question.parent_id
                );
              }

              if (
                question?.question_options &&
                question.category === "single_choice"
              ) {
                question.question_options
                  .filter((q) => q.question_detail_id === question.id)
                  .map((k, opIndex) => {
                    if (k.title === question?.answer) {
                      formData.set(
                        `answer_detail[${index}][question_options][${
                          question.category === "single_choice" ? 0 : opIndex
                        }][id]`,
                        k.id
                      );
                      formData.set(
                        `answer_detail[${index}][question_options][${
                          question.category === "single_choice" ? 0 : opIndex
                        }][value]`,
                        k.value === 0 ? 0 : k.value
                      );
                    }
                    return null;
                  });
              }
              let checkedIds = question.chekedAnswer?.map(
                (d) => d?.split(SPLIT_SYMBOL)[1]
              );
              if (
                question?.question_options &&
                question.category === "multi_choice"
              ) {
                console.log(
                  "question.question_options",
                  question.question_options
                );

                question.question_options
                  .filter(
                    (k) =>
                      k.question_detail_id === question.id &&
                      checkedIds.includes(k.id?.toString())
                  )
                  .map((k, opIndex) => {
                    console.log("checkedIds", checkedIds);
                    // if (
                    //   k.question_detail_id === question.id &&
                    //   checkedIds.includes(k.id?.toString())
                    // ) {
                    const { id, value } = k;
                    console.log(
                      "🚀 ~ file: PreConsultQuestionnaire.jsx:411 ~ question.question_options.reduce ~ value:",
                      value
                    );
                    formData.set(
                      `answer_detail[${index}][question_options][${opIndex}][id]`,
                      id
                    );
                    formData.set(
                      `answer_detail[${index}][question_options][${opIndex}][value]`,
                      value === 0 ? "0" : value
                    );
                    // }
                    return k;
                  });
              }
            }
            return null;
          });
        console.log("count", count);
        console.log("questionnaireIsEdited", questionnaireIsEdited);

        if (count === 0 || questionnaireIsEdited.length > 0) {
          let response = await submitPreConsultQuestionnaire(formData);

          if (response.status === 200) {
            setIsQuestionnaireSubmitted(true);
            let responseData = response?.data?.data;
            let questionnaire_indexes = responseData?.questionnaire_indexes;
            // setQuestionnaireIndexes(questionnaire_indexes);
            console.log("submitPreConsultQuestionnaire response", responseData);
            if (responseData.next_questionnaire_id) {
              //Next Questionnaire
              //   const next_questionnaire_id = responseData.next_questionnaire_id
              setCount(count + 1);
              if (passedToken) {
                setToBePassedToken(passedToken);
              } else {
                setToBePassedToken(responseData.token);
              }
              let currentFoundIndex = questionnaireDetails.findIndex(
                (d) => d.id === questionnaireDetails[activeQuestionnaire]?.id
              );
              const patient_answer_id = responseData.patient_answer_id;
              // Update the questionnaire details
              setQuestionnaireDetails((questionnaireDetails) => {
                // Create a copy of the questionnaire details array
                const updatedQuestionnaireDetails = [...questionnaireDetails];
                // Update the active questionnaire with the patient answer ID
                updatedQuestionnaireDetails[currentFoundIndex] = {
                  ...updatedQuestionnaireDetails[currentFoundIndex],
                  patient_answer_id: patient_answer_id,
                };
                // Return the updated questionnaire details
                return updatedQuestionnaireDetails;
              });

              let foundIndex = questionnaireDetails.findIndex(
                (d) => d.id === responseData.next_questionnaire_id
              );
              setActiveQuestionnaire(foundIndex);
              // if (activeQuestionnaire === questionnaireDetails.length) {
              //   setIsGatwayPoint(true);
              // }
              setLatestAnswerId(patient_answer_id);

              setQuestionnaireDetails((questionnaireDetails) => {
                // Create a copy of the questionnaire details array
                const updatedQuestionnaireDetails = [...questionnaireDetails];
                const updatedQuestionDetails = updatedQuestionnaireDetails[
                  foundIndex
                ]?.question_details.map((detail) => {
                  return {
                    ...detail,
                    error: null,
                    isEdited: true,
                  };
                });
                // Update the active questionnaire with the patient answer ID
                updatedQuestionnaireDetails[foundIndex] = {
                  ...updatedQuestionnaireDetails[foundIndex],
                  question_details: updatedQuestionDetails,
                  // patient_answer_id: patient_answer_id,
                };
                console.log(
                  "updatedQuestionnaireDetails",
                  updatedQuestionnaireDetails
                );
                // Return the updated questionnaire details
                return updatedQuestionnaireDetails;
              });
              let fetchedQuestionnaire =
                responseData?.questionnaire?.questionnaire_template;
              setAnswerReviewArray((answerReviewArray) => {
                // Create a copy of the answerReviewArray
                let answerReviewArrayState = answerReviewArray;

                // Find the index of the fetchedQuestionnaire in the answerReviewArray
                let answerFoundIndex = answerReviewArrayState.findIndex(
                  (d) => d.id === fetchedQuestionnaire.id
                );
                // If the fetchedQuestionnaire is already in the answerReviewArray
                if (answerFoundIndex !== -1) {
                  console.log("foundQuestionnaire", answerFoundIndex);
                  // Update the existing object in the answerReviewArray
                  answerReviewArrayState[answerFoundIndex] = {
                    ...fetchedQuestionnaire,
                    patient_answer_id: patient_answer_id,
                  };
                } else {
                  // Add the fetchedQuestionnaire to the answerReviewArray
                  console.log("new pushed", answerFoundIndex);

                  answerReviewArrayState.push({
                    ...fetchedQuestionnaire,
                    patient_answer_id: patient_answer_id,
                  });
                }
                let finalAnswerArr = [];
                Object.keys(questionnaire_indexes).forEach((key, index) => {
                  let foundId = questionnaire_indexes[key];
                  let latestAnswer = answerReviewArrayState?.find(
                    (d) => d.id === foundId
                  );
                  if (latestAnswer) {
                    finalAnswerArr.push(latestAnswer);
                  }
                });
                console.log("finalAnswerArr", finalAnswerArr);
                return finalAnswerArr;
              });
              if (answerReviewArray.length > pointerIndex) {
                setPointerIndex(pointerIndex + 1);
              }
              console.log(
                "🚀 ~ file: PreConsultQuestionnaire.jsx:264 ~ handleSubmit ~ foundIndex:",
                foundIndex
              );
              setFinalStep(false);

              let to_scroll_id = `answer_review_${foundIndex}`;
              let element = document.getElementById(to_scroll_id);
              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
              }
            } else {
              setToBePassedToken(responseData.token);
              let currentFoundIndex = questionnaireDetails.findIndex(
                (d) => d.id === questionnaireDetails[activeQuestionnaire]?.id
              );
              setIsGatwayPoint(responseData?.is_gateway_point);
              const patient_answer_id = responseData.patient_answer_id;
              setQuestionnaireDetails((questionnaireDetails) => {
                // Create a copy of the questionnaire details array
                const updatedQuestionnaireDetails = [...questionnaireDetails];
                // Update the active questionnaire with the patient answer ID
                updatedQuestionnaireDetails[currentFoundIndex] = {
                  ...updatedQuestionnaireDetails[currentFoundIndex],
                  patient_answer_id: patient_answer_id,
                };
                // Return the updated questionnaire details
                return updatedQuestionnaireDetails;
              });
              setOutCome({
                title: responseData.title,
                description: responseData.description,
                color_code: responseData.color_code,
                pre_consult_outcome: responseData?.pre_consult_outcome,
              });
              setLatestAnswerId(patient_answer_id);
              console.log("response data = = = > ", responseData);
              let fetchedQuestionnaire =
                responseData?.questionnaire?.questionnaire_template;
              setAnswerReviewArray((answerReviewArray) => {
                // Create a copy of the answerReviewArray
                let answerReviewArrayState = answerReviewArray;

                // Find the index of the fetchedQuestionnaire in the answerReviewArray
                if (fetchedQuestionnaire) {
                }
                let answerFoundIndex = answerReviewArrayState.findIndex(
                  (d) => d.id === fetchedQuestionnaire?.id
                );
                // If the fetchedQuestionnaire is already in the answerReviewArray
                if (answerFoundIndex !== -1) {
                  console.log("foundQuestionnaire", answerFoundIndex);
                  // Update the existing object in the answerReviewArray
                  answerReviewArrayState[answerFoundIndex] = {
                    ...fetchedQuestionnaire,
                    patient_answer_id: patient_answer_id,
                  };
                } else {
                  // Add the fetchedQuestionnaire to the answerReviewArray
                  console.log("new pushed", answerFoundIndex);
                  answerReviewArrayState.push({
                    ...fetchedQuestionnaire,
                    patient_answer_id: patient_answer_id,
                  });
                }
                let finalAnswerArr = [];
                if(questionnaire_indexes){
                  Object.keys(questionnaire_indexes).forEach((key, index) => {
                    let foundId = questionnaire_indexes[key];
                    let latestAnswer = answerReviewArrayState?.find(
                      (d) => d.id === foundId
                    );
                    if (latestAnswer) {
                      finalAnswerArr.push(latestAnswer);
                    }
                  });
                }

                console.log("finalAnswerArr", finalAnswerArr);
                return finalAnswerArr;
              });
              // if (pointerIndex >= 0) {
              //   // Update the state using the setState function
              //   setAnswerReviewArray((answerReviewArr) => {
              //     // Get the answerReviewArr from the state
              //     let newArr = answerReviewArr;
              //     // Calculate the updated index by adding 1 to the pointerIndex
              //     const updatedIndex = parseInt(pointerIndex + 1);
              //     // Create a new array by slicing the newArr up to the updated index
              //     let splicedArr = newArr.slice(0, updatedIndex);
              //     // Log the splicedArr to the console
              //     console.log("newArr", splicedArr);
              //     // Return the updated state with the splicedArr
              //     return splicedArr;
              //   });
              // }

              setFinalStep(true);
              setCount(count + 1);
              let to_scroll_id = `answer_review_${pointerIndex}`;
              let element = document.getElementById(to_scroll_id);
              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
              }
              setIsEditModeState(false);
            }
            setSubmittingQuestionnaire(false);
          }
        } else {
          setFinalStep(true);
          setSubmittingQuestionnaire(false);
          setIsEditModeState(false);
        }
      } catch (e) {
        let { message } = getErrorObject(e);
        errorToast({
          content: message,
        });
        setSubmittingQuestionnaire(false);
      }
    }
  };
  // useEffect(() => {
  //   setHyperTensiveAnswer(hypertensive);
  //   console.log("hypertensive", hypertensive);
  // }, [hypertensive]);
  const handleKioskPreConsultSubmit = async () => {
    console.log("questionnaireDetails--", questionnaireDetails);
    const errors = ValidateFillUpAnswer(
      questionnaireDetails[activeQuestionnaire],
      handleErrors,
      gender
    );
    if (errors.isValid) {
      console.log("questionnaireDetails", questionnaireDetails);
      //   setActiveQuestionnaire(activeQuestionnaire + 1);
      setSubmittingQuestionnaire(true);
      try {
        let formData = new FormData();
        let questionnaireToBeSubmitted = [];

        questionnaireDetails[activeQuestionnaire]?.question_details?.map(
          (q) => {
            questionnaireToBeSubmitted.push(q, ...q.children);
            return null;
          }
        );
        formData.set(`user_id`, patiendId);
        formData.set("health_scan_log_id", health_scan_log_id);
        formData.set(
          `questionnaire_template_id`,
          questionnaireDetails[activeQuestionnaire]?.id
        );
        // if (toBePassedToken) {
        //   console.log("toBePassedToken", toBePassedToken);
        //   formData.set(`token`, toBePassedToken);
        // }
        const questionnaireIsEdited = questionnaireDetails[
          activeQuestionnaire
        ]?.question_details?.filter((q) => q.isEdited === true);
        console.log(
          "🚀 ~ file: QuestionnaireFillUpTokenNew.jsx:462 ~ handleSubmitQuestionnaire ~ questionnaireIsEdited:",
          questionnaireIsEdited
        );
        // if (questionnaireIsEdited.length > 0 && isEditMode) {
        let tobePassedPatientAnswerId =
          questionnaireDetails[activeQuestionnaire]?.patient_answer_id;
        if (tobePassedPatientAnswerId) {
          formData.set(`patient_answer_id`, tobePassedPatientAnswerId);
        }
        // }
        // setQuestionnaireEdited(questionnaireIsEdited.length > 0 ? true : false);
        formData.set(`is_hypertension_questionnire`, true);
        // formData.set("health_condition_id", healthConditionId);
        questionnaireToBeSubmitted
          .filter(
            (d) =>
              d.gender_preference === null ||
              d.gender_preference === gender ||
              d.gender_preference === "both"
          )
          .map((question, index) => {
            console.log("question index", question, index);
            if (question.answer) {
              formData.set(
                `answer_detail[${index}][question_detail_id]`,
                question.id
              );
              formData.set(`answer_detail[${index}][answer]`, question.answer);
              if (question.is_child && question.parent_id) {
                formData.set(`answer_detail[${index}][is_child]`, true);
                formData.set(
                  `answer_detail[${index}][parent_id]`,
                  question.parent_id
                );
              }

              if (
                question?.question_options &&
                question.category === "single_choice"
              ) {
                question.question_options
                  .filter((q) => q.question_detail_id === question.id)
                  .map((k, opIndex) => {
                    if (k.title === question?.answer) {
                      formData.set(
                        `answer_detail[${index}][question_options][${
                          question.category === "single_choice" ? 0 : opIndex
                        }][id]`,
                        k.id
                      );
                      formData.set(
                        `answer_detail[${index}][question_options][${
                          question.category === "single_choice" ? 0 : opIndex
                        }][value]`,
                        k.value === 0 ? 0 : k.value
                      );
                    }
                    return null;
                  });
              }
              let checkedIds = question.chekedAnswer?.map(
                (d) => d?.split(SPLIT_SYMBOL)[1]
              );
              if (
                question?.question_options &&
                question.category === "multi_choice"
              ) {
                console.log(
                  "question.question_options",
                  question.question_options
                );

                question.question_options
                  .filter(
                    (k) =>
                      k.question_detail_id === question.id &&
                      checkedIds.includes(k.id?.toString())
                  )
                  .map((k, opIndex) => {
                    console.log("checkedIds", checkedIds);
                    // if (
                    //   k.question_detail_id === question.id &&
                    //   checkedIds.includes(k.id?.toString())
                    // ) {
                    const { id, value } = k;
                    console.log(
                      "🚀 ~ file: PreConsultQuestionnaire.jsx:411 ~ question.question_options.reduce ~ value:",
                      value
                    );
                    formData.set(
                      `answer_detail[${index}][question_options][${opIndex}][id]`,
                      id
                    );
                    formData.set(
                      `answer_detail[${index}][question_options][${opIndex}][value]`,
                      value === 0 ? "0" : value
                    );
                    // }
                    return k;
                  });
              }
            }
            return null;
          });
        console.log("count", count);
        console.log("questionnaireIsEdited", questionnaireIsEdited);

        let response = await submitPreConsultQuestionnaire(formData);
        if (response.status === 200) {
          setIsQuestionnaireSubmitted(true);
          _fetchHypertensiveQuestionnaire(); //re-fetching latest answers
          setFinalStep(true);
          let to_scroll_id = `answer_review_${pointerIndex}`;
          let element = document.getElementById(to_scroll_id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }

          setSubmittingQuestionnaire(false);
        }
      } catch (e) {
        let { message } = getErrorObject(e);
        errorToast({
          content: message,
        });
        setSubmittingQuestionnaire(false);
      }
    }
    // if (errors.isValid) {
    //   console.log("hyperTesiveAnswer", hyperTesiveAnswer);
    //   console.log(
    //     "handleKioskPreConsult SubmitquestionnaireDetails",
    //     questionnaireDetails[0]?.question_details[0]?.answer
    //   );
    //   setHyperTensiveAnswer(
    //     questionnaireDetails[0]?.question_details[0]?.answer
    //   );
    //   setFittedAbpmAnswer(questionnaireDetails[1]?.question_details[1]?.answer);
    //   setFinalStep(true);
    // }
  };
  console.log("questionnaireIndexes", questionnaireIndexes);
  useEffect(() => {
    // setLatestBpValues({
    //   systolic_bp: systolic_bp,
    //   diastolic_bp: diastolic_bp,
    //   pulse_per_minute: pulse_per_minute,
    // });
  }, [systolic_bp, diastolic_bp, pulse_per_minute]);
  useEffect(() => {
    console.log("outCome", finalOutCome);
    console.log("expandClicked", expandClicked);

    // let outComePayloads = {
    //   consultationNotes: payloadForConsultation,
    //   patient_answer_ids: patient_answer_ids?.filter((d) => d !== undefined),
    //   latestAnswerId: latestAnswerId,
    //   token: toBePassedToken,
    //   isGateWayPoint: isGateWayPoint,
    //   isQuestionnaireSubmitted: isQuestionnaireSubmitted,
    //   is_saftey_netting: is_saftey_netting,
    // };
    if (expandClicked || isGateWayPoint) {
      const regex = /(<([^>]+)>)/gi;

      let questionAnswerString = "";
      answerReviewArray.map((d) => {
        questionAnswerString += d?.name + "\n";
        d.question_details?.map((k) => {
          let answer =
            isArray(k.pre_consult_answer?.actual_answer) &&
            k.pre_consult_answer?.actual_answer[0]
              ? k.pre_consult_answer?.actual_answer[0]
              : k.pre_consult_answer?.actual_answer
              ? k.pre_consult_answer?.actual_answer
              : k.answer;
          console.log("K Answer", answer);

          console.log(
            " k.pre_consult_answer?.actual_answer",
            k.pre_consult_answer?.actual_answer
          );
          if (k.category === "drop_down") {
            answer = map(
              k.pre_consult_answer?.actual_answer,
              "selected_value"
            )?.toString();
          }
          if (!isEmpty(answer)) {
            questionAnswerString +=
              " " +
              k.title?.replace(regex, "")?.replace("&nbsp", "") +
              "-" +
              " " +
              " " +
              answer +
              "\n";
          }
          // else {
          //   questionAnswerString +=
          //     " " +
          //     k.title?.replace(regex, "")?.replace("&nbsp", "") +
          //     "-" +
          //     " " +
          //     "Not Answered" +
          //     "\n";
          // }
          d?.children?.forEach((e) => {
            let childAnswer =
              isArray(e.pre_consult_answer?.actual_answer) &&
              e.pre_consult_answer?.actual_answer[0]
                ? e.pre_consult_answer?.actual_answer[0]
                : e.pre_consult_answer?.actual_answer
                ? e.pre_consult_answer?.actual_answer
                : e.answer;
            if (e.category === "drop_down") {
              childAnswer = map(
                e.pre_consult_answer?.actual_answer,
                "selected_value"
              )?.toString();
              // console.log("K Answer", k);
            }
            if (!isEmpty(childAnswer)) {
              questionAnswerString +=
                "   " +
                e.title?.replace(regex, "")?.replace("&nbsp", "") +
                "-" +
                "   " +
                " " +
                "" +
                childAnswer +
                "\n";
            }
            // else {
            //   questionAnswerString +=
            //     "   " +
            //     e.title?.replace(regex, "")?.replace("&nbsp", "") +
            //     "-" +
            //     "   " +
            //     "Not Answered" +
            //     "\n";
            // }

            e?.children?.forEach((f) => {
              let childOfChildAnswer =
                isArray(f.pre_consult_answer?.actual_answer) &&
                f.pre_consult_answer?.actual_answer[0]
                  ? f.pre_consult_answer?.actual_answer[0]
                  : f.pre_consult_answer?.actual_answer
                  ? f.pre_consult_answer?.actual_answer
                  : f.answer;
              if (!isEmpty(childOfChildAnswer)) {
                questionAnswerString +=
                  "       " +
                  f.title?.replace(regex, "")?.replace("&nbsp", "") +
                  "-" +
                  "       " +
                  childOfChildAnswer +
                  "\n";
              }
              // else {
              //   questionAnswerString +=
              //     "      " +
              //     f.title?.replace(regex, "")?.replace("&nbsp", "") +
              //     "-" +
              //     "     " +
              //     "Not Answered" +
              //     "\n";
              // }
            });
          });
          return null;
        });

        return null;
      });
      console.log("expandClicked While True", answerReviewArray);

      let patient_answer_ids = map(answerReviewArray, "patient_answer_id");

      let snomedCodeParams = {};
      if (healthConditionSnomedCode && healthConditionSnomedCode?.code) {
        snomedCodeParams = {
          snomed_codes: {
            code: healthConditionSnomedCode?.code,
            description: healthConditionSnomedCode?.description,
            full_code: `${healthConditionSnomedCode?.code} - ${healthConditionSnomedCode?.description}`,
            id: healthConditionSnomedCode?.id,
          },
        };
      }
      let payloadForConsultation = {
        history: healthConditionName,
        pre_consult_note: questionAnswerString,
        pre_consult_tag: "pharmacy_first",
        management_plan: outCome?.pre_consult_outcome,
        patient_id: patiendId,
        ...snomedCodeParams,
        outcome: {
          comment: outcomeComment === "true" ? comment_input : outcomeComment,
        },
      };
      let outComePayloads = {
        consultationNotes: payloadForConsultation,
        patient_answer_ids: patient_answer_ids?.filter((d) => d !== undefined),
        latestAnswerId: latestAnswerId,
        token: toBePassedToken,
        isGateWayPoint: isGateWayPoint,
        isQuestionnaireSubmitted: isQuestionnaireSubmitted,
        template_id: template_id,
        serviceName: serviceName,
        is_saftey_netting: is_saftey_netting,
      };
      console.log("outComePayloads Expand Clicked", outComePayloads);
      console.log("questionAnswerString Expand Clicked", questionAnswerString);
      console.log(
        "Final outcome In ",
        finalOutCome,
        outCome,
        outComePayloads,
        questionAnswerString
      );

      getOutcome(outCome, happy_to_review_submit, outComePayloads);
    } else {
      if (preConsultStatus === "kiosk_pending") {
        let fittedAbpmAnswerParams = ``;
        if (fittedAbpmAnswer) {
          fittedAbpmAnswerParams = `Fitted with ABPM - ${fittedAbpmAnswer}`;
        }
        const regex = /(<([^>]+)>)/gi;

        let questionAnswerString = "";
        answerReviewArray.map((d) => {
          questionAnswerString += d?.name + "\n";
          d.question_details?.map((k) => {
            console.log("K Answer", k);
            if (k.id) {
              if (!isEmpty(k.answer)) {
                // let answer = k.pre_consult_answer?.actual_answer[0]
                //   ? k.pre_consult_answer?.actual_answer[0]
                //   : k.pre_consult_answer?.actual_answer
                //   ? k.pre_consult_answer?.actual_answer
                //   : k.answer;
                questionAnswerString +=
                  " " +
                  k.title?.replace(regex, "")?.replace("&nbsp", "") +
                  "-" +
                  " " +
                  k.answer +
                  "\n";
              }

              k?.children?.forEach((e) => {
                // let childAnswer = e.pre_consult_answer?.actual_answer[0]p@hann
                //   ? e.pre_consult_answer?.actual_answer[0]
                //   : e.pre_consult_answer?.actual_answer
                //   ? e.pre_consult_answer?.actual_answer
                //   : e.answer;
                if (!isEmpty(e.answer)) {
                  questionAnswerString +=
                    "   " +
                    e.title?.replace(regex, "")?.replace("&nbsp", "") +
                    "-" +
                    "   " +
                    e.answer +
                    "\n";
                }
                // else {
                //   questionAnswerString +=
                //     "   " +
                //     e.title?.replace(regex, "")?.replace("&nbsp", "") +
                //     "-" +
                //     "   " +
                //     "Not Answered" +
                //     "\n";
                // }

                e?.children?.forEach((f) => {
                  // let childOfChildAnswer = f.pre_consult_answer?.actual_answer[0]
                  //   ? f.pre_consult_answer?.actual_answer[0]
                  //   : f.pre_consult_answer?.actual_answer
                  //   ? f.pre_consult_answer?.actual_answer
                  //   : f.answer;
                  if (!isEmpty(f.answer)) {
                    questionAnswerString +=
                      "       " +
                      f.title?.replace(regex, "")?.replace("&nbsp", "") +
                      "-" +
                      "       " +
                      f.answer +
                      "\n";
                  }
                  // else {
                  //   questionAnswerString +=
                  //     "      " +
                  //     f.title?.replace(regex, "")?.replace("&nbsp", "") +
                  //     "-" +
                  //     "     " +
                  //     "Not Answered" +
                  //     "\n";
                  // }
                });
              });
            }

            return null;
          });
          return null;
        });
        let payloadForConsultation = {
          // pre_consult_note: `Known Hypertensive - ${hypertensive}`,
          pre_consult_note: questionAnswerString,
          pre_consult_tag: "hypertensive_case_finding",
          examination: `SCREENED \n\npulse :${
            screened_bp_values?.pulse_per_minute
              ? `${screened_bp_values?.pulse_per_minute}bpm\n`
              : ""
          }systolic : ${
            screened_bp_values?.systolic_bp
              ? `${screened_bp_values?.systolic_bp}mmHg\n`
              : ""
          }diastolic :  ${
            screened_bp_values?.diastolic_bp
              ? `${screened_bp_values?.diastolic_bp}mmHg\n`
              : ""
          }----------\n\n\n${
            latestBpValues && latestBpValues?.diastolic_bp
              ? `pulse : ${latestBpValues?.pulse_per_minute}bpm\n`
              : ""
          }${
            latestBpValues && latestBpValues?.systolic_bp
              ? `systolic : ${latestBpValues?.systolic_bp}mmHg\n`
              : ""
          }${
            latestBpValues && latestBpValues?.diastolic_bp
              ? `diastolic : ${latestBpValues?.diastolic_bp}mmHg\n`
              : ""
          }`,
          management_plan: fittedAbpmAnswerParams,
          // patient_id: patiendId,
          // ...snomedCodeParams,
          // snomed_codes: {
          //   code: healthConditionSnomedCode?.code,
          //   description: healthConditionSnomedCode?.description,
          //   full_code: `${healthConditionSnomedCode?.code} - ${healthConditionSnomedCode?.description}`,
          //   id: healthConditionSnomedCode?.id,
          // },
          outcome: {
            comment: outcomeComment === "true" ? comment_input : outcomeComment,
          },
        };
        console.log(
          "🚀 ~ useEffect ~ payloadForConsultation:",
          payloadForConsultation
        );
        let patient_answer_ids = map(answerReviewArray, "patient_answer_id");
        let healthSummaryParams = {};
        if (
          latestBpValues?.systolic_bp &&
          latestBpValues?.diastolic_bp &&
          latestBpValues?.pulse_per_minute
        ) {
          healthSummaryParams = {
            health_summary: {
              systolic_bp: latestBpValues?.systolic_bp,
              diastolic_bp: latestBpValues?.diastolic_bp,
              pulse_per_minute: latestBpValues?.pulse_per_minute,
            },
          };
        }
        let hyperTensiveParams = {};
        if (hyperTesiveAnswer) {
          hyperTensiveParams = {
            hypertensive: hyperTesiveAnswer,
          };
        }
        let outComePayloads = {
          consultationNotes: payloadForConsultation,
          patient_answer_ids: patient_answer_ids?.filter(
            (d) => d !== undefined
          ),
          latestAnswerId: latestAnswerId,
          token: toBePassedToken,
          isGateWayPoint: isGateWayPoint,
          isQuestionnaireSubmitted: isQuestionnaireSubmitted,
          template_id: template_id,
          ...healthSummaryParams,
          // health_summary: {
          //   systolic_bp: latestBpValues?.systolic_bp
          //     ? latestBpValues?.systolic_bp
          //     : screened_bp_values?.systolic_bp,
          //   diastolic_bp: latestBpValues?.diastolic_bp
          //     ? latestBpValues?.diastolic_bp
          //     : screened_bp_values?.diastolic_bp,
          //   pulse_per_minute: latestBpValues?.pulse_per_minute
          //     ? latestBpValues?.pulse_per_minute
          //     : screened_bp_values?.pulse_per_minute,
          // },
          ...hyperTensiveParams,
          latestBpValues: latestBpValues,
          is_saftey_netting: is_saftey_netting,
        };
        console.log("outComePayloads", outComePayloads);
        getOutcome(finalOutCome, happy_to_review_submit, outComePayloads);
      } else if (finalOutCome && happy_to_review_submit && is_saftey_netting) {
        console.log("Final outcome In 1", finalOutCome);

        const regex = /(<([^>]+)>)/gi;

        let questionAnswerString = "";
        answerReviewArray.map((d) => {
          questionAnswerString += d?.name + "\n";
          d.question_details?.map((k) => {
            console.log("K Answer", k);
            let answer = k.pre_consult_answer?.actual_answer[0]
              ? k.pre_consult_answer?.actual_answer[0]
              : k.pre_consult_answer?.actual_answer
              ? k.pre_consult_answer?.actual_answer
              : k.answer;
            if (k.category === "drop_down") {
              answer = map(
                k.pre_consult_answer?.actual_answer,
                "selected_value"
              )?.toString();
            }
            if (!isEmpty(answer)) {
              questionAnswerString +=
                " " +
                k.title?.replace(regex, "")?.replace("&nbsp", "") +
                "-" +
                " " +
                "" +
                answer +
                "\n";
            }
            //  else {
            //   questionAnswerString +=
            //     " " +
            //     k.title?.replace(regex, "")?.replace("&nbsp", "") +
            //     "-" +
            //     " " +
            //     "Not Answered" +
            //     "\n";
            // }
            d?.children?.forEach((e) => {
              let childAnswer = e.pre_consult_answer?.actual_answer[0]
                ? e.pre_consult_answer?.actual_answer[0]
                : e.pre_consult_answer?.actual_answer
                ? e.pre_consult_answer?.actual_answer
                : e.answer;
              if (e.category === "drop_down") {
                childAnswer = map(
                  e.pre_consult_answer?.actual_answer,
                  "selected_value"
                )?.toString();
                // console.log("K Answer", k);
              }
              if (!isEmpty(childAnswer)) {
                questionAnswerString +=
                  "   " +
                  e.title?.replace(regex, "")?.replace("&nbsp", "") +
                  "-" +
                  "   " +
                  "" +
                  childAnswer +
                  "\n";
              }
              // else {
              //   questionAnswerString +=
              //     "   " +
              //     e.title?.replace(regex, "")?.replace("&nbsp", "") +
              //     "-" +
              //     "   " +
              //     "Not Answered" +
              //     "\n";
              // }

              e?.children?.forEach((f) => {
                let childOfChildAnswer = f.pre_consult_answer?.actual_answer[0]
                  ? f.pre_consult_answer?.actual_answer[0]
                  : f.pre_consult_answer?.actual_answer
                  ? f.pre_consult_answer?.actual_answer
                  : f.answer;
                if (!isEmpty(childOfChildAnswer)) {
                  questionAnswerString +=
                    "       " +
                    f.title?.replace(regex, "")?.replace("&nbsp", "") +
                    "-" +
                    "       " +
                    childOfChildAnswer +
                    "\n";
                }
                // else {
                //   questionAnswerString +=
                //     "      " +
                //     f.title?.replace(regex, "")?.replace("&nbsp", "") +
                //     "-" +
                //     "     " +
                //     "Not Answered" +
                //     "\n";
                // }
              });
            });
            return null;
          });

          return null;
        });

        let patient_answer_ids = map(answerReviewArray, "patient_answer_id");
        let snomedCodeParams = {};
        if (healthConditionSnomedCode && healthConditionSnomedCode?.code) {
          snomedCodeParams = {
            snomed_codes: {
              code: healthConditionSnomedCode?.code,
              description: healthConditionSnomedCode?.description,
              full_code: `${healthConditionSnomedCode?.code} - ${healthConditionSnomedCode?.description}`,
              id: healthConditionSnomedCode?.id,
            },
          };
        }
        let payloadForConsultation = {
          history: healthConditionName,
          pre_consult_note: questionAnswerString,
          pre_consult_tag: "pharmacy_first",
          management_plan: finalOutCome?.pre_consult_outcome,
          patient_id: patiendId,
          ...snomedCodeParams,
          // snomed_codes: {
          //   code: healthConditionSnomedCode?.code,
          //   description: healthConditionSnomedCode?.description,
          //   full_code: `${healthConditionSnomedCode?.code} - ${healthConditionSnomedCode?.description}`,
          //   id: healthConditionSnomedCode?.id,
          // },
          outcome: {
            comment: outcomeComment === "true" ? comment_input : outcomeComment,
          },
        };
        let outComePayloads = {
          consultationNotes: payloadForConsultation,
          patient_answer_ids: patient_answer_ids?.filter(
            (d) => d !== undefined
          ),
          latestAnswerId: latestAnswerId,
          token: toBePassedToken,
          isGateWayPoint: isGateWayPoint,
          isQuestionnaireSubmitted: isQuestionnaireSubmitted,
          is_saftey_netting: is_saftey_netting,
          template_id: template_id,
          serviceName: serviceName,
        };
        console.log("outComePayloads", outComePayloads);

        getOutcome(finalOutCome, happy_to_review_submit, outComePayloads);
      } else if (finalOutCome && happy_to_review_submit) {
        console.log("Final outcome In 2", finalOutCome);
        const regex = /(<([^>]+)>)/gi;

        let questionAnswerString = "";
        answerReviewArray.map((d) => {
          questionAnswerString += d?.name + "\n";
          d.question_details?.map((k) => {
            let answer = k.pre_consult_answer?.actual_answer[0]
              ? k.pre_consult_answer?.actual_answer[0]
              : k.pre_consult_answer?.actual_answer
              ? k.pre_consult_answer?.actual_answer
              : k.answer;
            if (k.category === "drop_down") {
              answer = map(
                k.pre_consult_answer?.actual_answer,
                "selected_value"
              )?.toString();
              console.log("K Answer", k);
            }
            if (!isEmpty(answer)) {
              questionAnswerString +=
                " " +
                k.title?.replace(regex, "")?.replace("&nbsp", "") +
                "-" +
                " " +
                " " +
                answer +
                "\n";
            }
            // else {
            //   questionAnswerString +=
            //     " " +
            //     k.title?.replace(regex, "")?.replace("&nbsp", "") +
            //     "-" +
            //     " " +
            //     "Not Answered" +
            //     "\n";
            // }
            d?.children?.forEach((e) => {
              let childAnswer = e.pre_consult_answer?.actual_answer[0]
                ? e.pre_consult_answer?.actual_answer[0]
                : e.pre_consult_answer?.actual_answer
                ? e.pre_consult_answer?.actual_answer
                : e.answer;
              if (e.category === "drop_down") {
                childAnswer = map(
                  e.pre_consult_answer?.actual_answer,
                  "selected_value"
                )?.toString();
                // console.log("K Answer", k);
              }
              if (!isEmpty(childAnswer)) {
                questionAnswerString +=
                  "   " +
                  e.title?.replace(regex, "")?.replace("&nbsp", "") +
                  "-" +
                  "   " +
                  " " +
                  "" +
                  childAnswer +
                  "\n";
              }
              // else {
              //   questionAnswerString +=
              //     "   " +
              //     e.title?.replace(regex, "")?.replace("&nbsp", "") +
              //     "-" +
              //     "   " +
              //     "Not Answered" +
              //     "\n";
              // }

              e?.children?.forEach((f) => {
                let childOfChildAnswer = f.pre_consult_answer?.actual_answer[0]
                  ? f.pre_consult_answer?.actual_answer[0]
                  : f.pre_consult_answer?.actual_answer
                  ? f.pre_consult_answer?.actual_answer
                  : f.answer;
                if (!isEmpty(childOfChildAnswer)) {
                  questionAnswerString +=
                    "       " +
                    f.title?.replace(regex, "")?.replace("&nbsp", "") +
                    "-" +
                    "       " +
                    childOfChildAnswer +
                    "\n";
                }
                // else {
                //   questionAnswerString +=
                //     "      " +
                //     f.title?.replace(regex, "")?.replace("&nbsp", "") +
                //     "-" +
                //     "     " +
                //     "Not Answered" +
                //     "\n";
                // }
              });
            });
            return null;
          });

          return null;
        });

        let patient_answer_ids = map(answerReviewArray, "patient_answer_id");

        let snomedCodeParams = {};
        if (healthConditionSnomedCode && healthConditionSnomedCode?.code) {
          snomedCodeParams = {
            snomed_codes: {
              code: healthConditionSnomedCode?.code,
              description: healthConditionSnomedCode?.description,
              full_code: `${healthConditionSnomedCode?.code} - ${healthConditionSnomedCode?.description}`,
              id: healthConditionSnomedCode?.id,
            },
          };
        }
        let payloadForConsultation = {
          history: healthConditionName,
          pre_consult_note: questionAnswerString,
          pre_consult_tag: "pharmacy_first",
          management_plan: finalOutCome?.pre_consult_outcome,
          patient_id: patiendId,
          ...snomedCodeParams,
          // snomed_codes: {
          //   code: healthConditionSnomedCode?.code,
          //   description: healthConditionSnomedCode?.description,
          //   full_code: `${healthConditionSnomedCode?.code} - ${healthConditionSnomedCode?.description}`,
          //   id: healthConditionSnomedCode?.id,
          // },
          outcome: {
            comment: outcomeComment === "true" ? comment_input : outcomeComment,
          },
        };
        let outComePayloads = {
          consultationNotes: payloadForConsultation,
          patient_answer_ids: patient_answer_ids?.filter(
            (d) => d !== undefined
          ),
          latestAnswerId: latestAnswerId,
          token: toBePassedToken,
          isGateWayPoint: isGateWayPoint,
          isQuestionnaireSubmitted: isQuestionnaireSubmitted,
          template_id: template_id,
          serviceName: serviceName,
          is_saftey_netting: is_saftey_netting,
        };
        console.log("outComePayloads", outComePayloads);
        console.log("PALis_saftey_netting", is_saftey_netting);
        console.log("questionAnswerString", questionAnswerString);

        getOutcome(finalOutCome, happy_to_review_submit, outComePayloads);
      } else if (!happy_to_review_submit && !finalOutCome && toBePassedToken) {
        console.log("Final outcome In 3", finalOutCome);

        let outComePayloads = {
          consultationNotes: null,
          patient_answer_ids: null,
          latestAnswerId: null,
          token: toBePassedToken,
          isGateWayPoint: null,
          isQuestionnaireSubmitted: isQuestionnaireSubmitted,
          template_id: template_id,
          serviceName: serviceName,
          is_saftey_netting: is_saftey_netting,
        };
        console.log("outComePayloads", outComePayloads);
        console.log("PALis_saftey_netting", is_saftey_netting);

        getOutcome(null, false, outComePayloads);
      } else {
        console.log("Final outcome In 3", finalOutCome);
        if (!happy_to_review_submit) {
          let outComePayloads = {
            consultationNotes: null,
            patient_answer_ids: null,
            latestAnswerId: null,
            token: toBePassedToken,
            isGateWayPoint: null,
            isQuestionnaireSubmitted: isQuestionnaireSubmitted,
            serviceName: serviceName,

            // is_saftey_netting: is_saftey_netting,
          };
          getOutcome(finalOutCome, false, outComePayloads);
        }
      }
    }
  }, [
    finalOutCome,
    happy_to_review_submit,
    toBePassedToken,
    is_saftey_netting,
    outcomeComment,
    template_id,
    latestBpValues?.systolic_bp,
    latestBpValues?.diastolic_bp,
    latestBpValues?.pulse_per_minute,
    comment_input,
    expandClicked,
    serviceName,
    isGateWayPoint,
  ]);

  const _createPreConsultation = () => {
    store.dispatch({
      type: "SET_POST_GT_QUESTIONNAIRE",
      payload: null,
    });
    setFinalOutCome(outCome);
    setFinalStep(false);
    setIsEditModeState(false);
  };

  const seekToQuestionnare = (passedQuestionnaire, index) => {
    setHappyToReviewSubmit(false);

    let questionnairIndex = questionnaireDetails?.findIndex(
      (d) => d.id === passedQuestionnaire.id
    );
    console.log("Question passedQuestionnaire", questionnairIndex);
    console.log("Question passedQuestionnaire 1", passedQuestionnaire);

    setQuestionnaireDetails((questionnaire) => {
      //updating drop down category answer with updated answers
      let questionDetails = passedQuestionnaire?.question_details;
      console.log("setQuestionnaireDetails questionDetails", questionDetails);
      questionDetails.map((e) => {
        if (e.category === "multi_choice") {
          let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
            (d) =>
              `${d}${SPLIT_SYMBOL}${
                e?.question_options?.find((v) => v.title === d)?.id
              }`
          );
          e.chekedAnswer = checkedAnswers;
          e.answer = checkedAnswers;
        } else if (e.category === "drop_down") {
          e.answer = map(
            e.pre_consult_answer?.actual_answer,
            "id",
            []
          )?.toString();
        } else {
          e.answer = isArray(e.pre_consult_answer?.actual_answer)
            ? e.pre_consult_answer?.actual_answer[0]
            : e.pre_consult_answer?.actual_answer
            ? e.pre_consult_answer?.actual_answer
            : e.answer;
        }
        e.children.map((f) => {
          f.answer = isArray(f.pre_consult_answer?.actual_answer)
            ? f.pre_consult_answer?.actual_answer[0]
            : f.pre_consult_answer?.actual_answer
            ? f.pre_consult_answer?.actual_answer
            : f.answer;
          if (f.category === "multi_choice") {
            let checkedAnswers = f.pre_consult_answer?.actual_answer?.map(
              (d) =>
                `${d}${SPLIT_SYMBOL}${
                  f?.question_options?.find((v) => v.title === d)?.id
                }`
            );
            f.chekedAnswer = checkedAnswers;
            f.answer = checkedAnswers;
          }
          if (f.category === "drop_down") {
            f.answer = map(
              f.pre_consult_answer?.actual_answer,
              "id",
              []
            )?.toString();
          }
          f.children.map((g) => {
            g.answer = isArray(g.pre_consult_answer?.actual_answer)
              ? g.pre_consult_answer?.actual_answer[0]
              : g.pre_consult_answer?.actual_answer
              ? g.pre_consult_answer?.actual_answer
              : g.answer;
            if (g.category === "multi_choice") {
              let checkedAnswers = g.pre_consult_answer?.actual_answer?.map(
                (d) =>
                  `${d}${SPLIT_SYMBOL}${
                    g?.question_options?.find((v) => v.title === d)?.id
                  }`
              );
              g.chekedAnswer = checkedAnswers;
            }
            if (g.category === "drop_down") {
              g.answer = map(
                g.pre_consult_answer?.actual_answer,
                "id",
                []
              )?.toString();
            }
            return g;
          });
          return f;
        });
        return e;
      });
      questionnaire[questionnairIndex] = {
        ...questionnaire[questionnairIndex],
        question_details: questionDetails,
      };

      console.log("updated questionnaire", questionnaire);

      return questionnaire;
    });
    setActiveQuestionnaire(questionnairIndex);
    setPointerIndex(questionnairIndex);
    setIsEditMode(true);
    setInitialQuestionnaire(false);
    setFinalStep(false);
    setFinalOutCome(null);
    setIsEditModeState(true);
  };

  const _handleTemplateChange = (v) => {
    console.log("Selected Template", v);
    setTemplates(v);
  };
  console.log("isShowBookingAppt", isShowBookingAppt);
  console.log("finalOutCome", finalOutCome);
  console.log("selectedService", selectedItem);
  return (
    <div className="mainquestionnairewrap PreConsultQuestionnaire_wrapper">
      <div className="row">
        {
          <div className="col-md-12">
            {/*  Displaying Questionnaire */}
            {fetchingQuestionnaire && <CommonLoader />}
            {!fetchingQuestionnaire && (
              <Fragment>
                {!finalOutCome && !finalStep && (
                  <Fragment>
                    <Divider orientation="left">
                      {" "}
                      <b>Questionnaire</b>{" "}
                    </Divider>
                    {!isFromPreConsultListing && (
                      <p>
                        {selectedItem?.name ? (
                          <h5 className="questionnairename">
                            {selectedItem?.name}
                          </h5>
                        ) : null}
                      </p>
                    )}

                    <Collapse
                      //   onChange={handleCollapsiable}
                      defaultActiveKey={["0"]}
                      //   ghost
                      accordion
                      expandIconPosition="right"
                      bordered
                      activeKey={activeQuestionnaire}
                      className="mb-3"
                    >
                      {
                        <Fragment>
                          {questionnaireDetails &&
                            isArray(questionnaireDetails) &&
                            questionnaireDetails?.map((d, index) => {
                              return (
                                <Panel
                                  collapsible={
                                    activeQuestionnaire === index
                                      ? "header"
                                      : "disabled"
                                  }
                                  key={index}
                                  header={`Section ${index + 1}`}
                                >
                                  <div className="p-0">
                                    {d?.question_details?.map((q) => {
                                      return (
                                        <QuestionnaireFillUpItem
                                          questions={q}
                                          handleAnswerChange={
                                            handleAnswerChange
                                          }
                                          handleErrors={handleErrors}
                                          gender={gender}
                                        />
                                      );
                                    })}
                                  </div>
                                  <div className="questionairesubmit">
                                    <Button
                                      disabled={isSubmittingQuestionnaire}
                                      loading={isSubmittingQuestionnaire}
                                      onClick={
                                        preConsultStatus === "kiosk_pending"
                                          ? handleKioskPreConsultSubmit
                                          : handleSubmit
                                      }
                                      type="primary"
                                    >
                                      {" "}
                                      Submit{" "}
                                    </Button>
                                  </div>
                                </Panel>
                              );
                            })}
                        </Fragment>
                      }
                    </Collapse>
                  </Fragment>
                )}
                {/*  Displaying Outcome */}
                {/* {nextQuestionnaireID ? "Y" : "N"}
                <br />
                {isEditMode ? "Y" : "N"}
                {fromAnswerView ? "Y" : "N"}
                {JSON.stringify(finalOutCome)} */}
                {finalOutCome &&
                  finalOutCome.pre_consult_outcome &&
                  preConsultStatus !== "kiosk_pending" &&
                  preConsultStatus !== "kiosk_reviewed" &&
                  (isEditMode || nextQuestionnaireID === null) &&
                  !fromAnswerView && (
                    <div className="center-block flex flex-left justify-content-center mt-3">
                      <Divider orientation="left">
                        <b>Informational OutCome </b>
                      </Divider>
                      <div
                        className="outcomepart"
                        style={{ borderColor: outCome?.color_code }}
                      >
                        <div className="outcomediv">
                          <h3>{outCome?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: outCome?.description,
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column form-group">
                        <Checkbox
                          name="office_action_needed"
                          onChange={() => {
                            setHappyToReviewSubmit(!happy_to_review_submit);
                            setFinalStep(false);
                          }}
                        >
                          Happy to review and submit
                        </Checkbox>

                        <Checkbox
                          className="ml-0"
                          onChange={() => {
                            setIsSafetyNetting(!is_saftey_netting);
                            setFinalStep(false);
                          }}
                        >
                          Patient has been provided safety netting as
                          recommended
                        </Checkbox>
                      </div>
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sel1" className="font-12">
                              OutCome
                            </label>
                            <select
                              onChange={(e) => {
                                console.log("SSSSSS", e?.target?.value);
                                setOutComeComment(e?.target?.value);
                              }}
                              name="comment"
                              // value={comment}
                              className={`form-control height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                            >
                              <option
                                value=""
                                disabled="disabled"
                                selected="selected"
                              >
                                Select Option
                              </option>
                              <option value="Advice given only (no medicine supply)">
                                Advice given only (no medicine supply)
                              </option>
                              <option value="Sale of an Over the Counter (OTC) medicine">
                                Sale of an Over the Counter (OTC) medicine
                              </option>
                              <option value="Referral into a pharmacy local minor ailments service (MAS)">
                                Referral into a pharmacy local minor ailments
                                service (MAS)
                              </option>
                              <option value="Referral into an appropriate locally commissioned NHS service, such as a patient group direction">
                                Referral into an appropriate locally
                                commissioned NHS service, such as a patient
                                group direction
                              </option>
                              <option value="Onward referral to another pharmacy">
                                Onward referral to another pharmacy
                              </option>
                              <option value="Non-urgent signposting to another service">
                                Non-urgent signposting to another service
                              </option>
                              <option value="Urgent escalation to another service">
                                Urgent escalation to another service
                              </option>
                              <option value="Clinical pathways Consultation">
                                Clinical pathways Consultation
                              </option>
                              <option value="Medicines supply">
                                Medicines supply
                              </option>
                              <option value={true}>Other</option>
                            </select>
                            {outcomeComment === "true" && (
                              <Input.TextArea
                                autoSize={true}
                                rows="2"
                                onChange={handleCommentInputChange}
                                form="consultation"
                                value={comment_input}
                                className={`form-control mt-2 height-auto custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                                name={"comment_input"}
                                placeholder="Comment"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <TemplateDropDown
                            _handleTemplateChange={_handleTemplateChange}
                            template_id={template_id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {finalOutCome &&
                  preConsultStatus !== "kiosk_pending" &&
                  preConsultStatus !== "kiosk_reviewed" &&
                  finalOutCome.pre_consult_outcome &&
                  isEditMode &&
                  nextQuestionnaireID === null &&
                  fromAnswerView && (
                    <div className="center-block flex flex-left justify-content-center mt-3">
                      <Divider orientation="left">
                        <b>Informational OutCome</b>
                      </Divider>
                      <div
                        className="outcomepart"
                        style={{ borderColor: outCome?.color_code }}
                      >
                        <div className="outcomediv">
                          <h3>{outCome?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: outCome?.description,
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column form-group">
                        <Checkbox
                          name="office_action_needed"
                          onChange={() => {
                            setHappyToReviewSubmit(!happy_to_review_submit);
                            setFinalStep(false);
                          }}
                        >
                          Happy to review and submit
                        </Checkbox>

                        <Checkbox
                          className="ml-0"
                          onChange={() => {
                            setIsSafetyNetting(!is_saftey_netting);
                            setFinalStep(false);
                          }}
                        >
                          Patient has been provided safety netting as
                          recommended
                        </Checkbox>
                      </div>
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sel1" className="font-12">
                              OutCome
                            </label>
                            <select
                              onChange={(e) => {
                                console.log("SSSSSS", e?.target?.value);
                                setOutComeComment(e?.target?.value);
                              }}
                              name="comment"
                              // value={comment}
                              className={`form-control height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                            >
                              <option
                                value=""
                                disabled="disabled"
                                selected="selected"
                              >
                                Select Option
                              </option>
                              <option value="Advice given only (no medicine supply)">
                                Advice given only (no medicine supply)
                              </option>
                              <option value="Sale of an Over the Counter (OTC) medicine">
                                Sale of an Over the Counter (OTC) medicine
                              </option>
                              <option value="Referral into a pharmacy local minor ailments service (MAS)">
                                Referral into a pharmacy local minor ailments
                                service (MAS)
                              </option>
                              <option value="Referral into an appropriate locally commissioned NHS service, such as a patient group direction">
                                Referral into an appropriate locally
                                commissioned NHS service, such as a patient
                                group direction
                              </option>
                              <option value="Onward referral to another pharmacy">
                                Onward referral to another pharmacy
                              </option>
                              <option value="Non-urgent signposting to another service">
                                Non-urgent signposting to another service
                              </option>
                              <option value="Urgent escalation to another service">
                                Urgent escalation to another service
                              </option>
                              <option value="Clinical pathways Consultation">
                                Clinical pathways Consultation
                              </option>
                              <option value="Medicines supply">
                                Medicines supply
                              </option>
                              <option value={true}>Other</option>
                            </select>
                            {outcomeComment === "true" && (
                              <Input.TextArea
                                autoSize={true}
                                rows="2"
                                onChange={handleCommentInputChange}
                                form="consultation"
                                value={comment_input}
                                className={`form-control mt-2 height-auto custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                                name={"comment_input"}
                                placeholder="Comment"
                              />
                            )}
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }} className="col-md-12">
                          <TemplateDropDown
                            _handleTemplateChange={_handleTemplateChange}
                            template_id={template_id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {finalOutCome &&
                  preConsultStatus !== "kiosk_pending" &&
                  preConsultStatus !== "kiosk_reviewed" &&
                  finalOutCome.pre_consult_outcome &&
                  !isEditMode &&
                  !fromAnswerView && (
                    <div className="center-block flex flex-left justify-content-center mt-3">
                      <Divider orientation="left">
                        <b>Informational OutCome</b>
                      </Divider>
                      <div
                        className="outcomepart"
                        style={{ borderColor: outCome?.color_code }}
                      >
                        <div className="outcomediv">
                          <h3>{outCome?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: outCome?.description,
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column form-group">
                        <Checkbox
                          name="office_action_needed"
                          onChange={() => {
                            setHappyToReviewSubmit(!happy_to_review_submit);
                            setFinalStep(false);
                          }}
                        >
                          Happy to review and submit
                        </Checkbox>

                        <Checkbox
                          className="ml-0"
                          onChange={() => {
                            setIsSafetyNetting(!is_saftey_netting);
                            setFinalStep(false);
                          }}
                        >
                          Patient has been provided safety netting as
                          recommended
                        </Checkbox>
                      </div>
                      {selectedItem && selectedItem?.services &&
                        isArray(selectedItem?.services) &&
                        selectedItem?.services.length === 2 && (
                          <div className="form-group mb-1">
                            <label>Service</label>

                            <div className="d-flex flex-wrap gapx-2">
                              <Checkbox
                                name="office_action_needed"
                                onChange={() => {
                                  setServiceName("NHS");
                                }}
                                checked={serviceName === "NHS"}
                              >
                                NHS
                              </Checkbox>

                              <Checkbox
                                className="ml-0"
                                onChange={() => {
                                  setServiceName("Private");
                                }}
                                checked={serviceName === "Private"}
                              >
                                Private
                              </Checkbox>
                            </div>
                          </div>
                        )}
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sel1" className="font-12">
                              OutCome
                            </label>
                            <select
                              onChange={(e) => {
                                console.log("SSSSSS", e?.target?.value);
                                setOutComeComment(e?.target?.value);
                              }}
                              name="comment"
                              // value={comment}
                              className={`form-control height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                            >
                              <option
                                value=""
                                disabled="disabled"
                                selected="selected"
                              >
                                Select Option
                              </option>
                              <option value="Advice given only (no medicine supply)">
                                Advice given only (no medicine supply)
                              </option>
                              <option value="Sale of an Over the Counter (OTC) medicine">
                                Sale of an Over the Counter (OTC) medicine
                              </option>
                              <option value="Referral into a pharmacy local minor ailments service (MAS)">
                                Referral into a pharmacy local minor ailments
                                service (MAS)
                              </option>
                              <option value="Referral into an appropriate locally commissioned NHS service, such as a patient group direction">
                                Referral into an appropriate locally
                                commissioned NHS service, such as a patient
                                group direction
                              </option>
                              <option value="Onward referral to another pharmacy">
                                Onward referral to another pharmacy
                              </option>
                              <option value="Non-urgent signposting to another service">
                                Non-urgent signposting to another service
                              </option>
                              <option value="Urgent escalation to another service">
                                Urgent escalation to another service
                              </option>
                              <option value="Clinical pathways Consultation">
                                Clinical pathways Consultation
                              </option>
                              <option value="Medicines supply">
                                Medicines supply
                              </option>
                              <option value={true}>Other</option>
                            </select>
                            {outcomeComment === "true" && (
                              <Input.TextArea
                                autoSize={true}
                                rows="2"
                                onChange={handleCommentInputChange}
                                form="consultation"
                                value={comment_input}
                                className={`form-control mt-2 height-auto custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                                name={"comment_input"}
                                placeholder="Comment"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <TemplateDropDown
                            _handleTemplateChange={_handleTemplateChange}
                            template_id={template_id}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                {finalStep && (
                  <AnswerReview
                    questionnaireWithAnswers={answerReviewArray}
                    createPreConsultation={_createPreConsultation}
                    seekToQuestionnare={seekToQuestionnare}
                    gender={gender}
                    passedToken={passedToken} // answers have been managed in questions details array
                    _handleTemplateChange={_handleTemplateChange}
                    happy_to_review_submit={happy_to_review_submit}
                    setFinalStep={setFinalStep}
                    setHappyToReviewSubmit={setHappyToReviewSubmit}
                    setOutComeComment={setOutComeComment}
                    outcomeComment={outcomeComment}
                    handleCommentInputChange={handleCommentInputChange}
                    template_id={template_id}
                    isEditMode={isEditMode}
                    setState={setState}
                    state={state}
                    setFinalOutCome={setFinalOutCome}
                    outCome={outCome}
                    preConsultStatus={preConsultStatus}
                    intialQuestionnaire={intialQuestionnaire}
                    nextQuestionnaireID={nextQuestionnaireID}
                    setFromAnswerView={setFromAnswerView}
                    fromAnswerView={fromAnswerView}
                    setIsSafetyNetting={(d)=>{
                      console.log("Setting Saftey",d)
                      setIsSafetyNetting(d)
                    }}
                    is_saftey_netting={is_saftey_netting}
                    setServiceName={setServiceName}
                    serviceName={serviceName}
                    isGateWayPoint={isGateWayPoint}
                    selectedItem={selectedItem}
                  />
                )}
                {preConsultStatus === "kiosk_pending" && finalStep && (
                  <Fragment>
                    <div className="consultantion-info-table-wrapper pt-0">
                      <div className="table-wrapper maintable">
                        <div className="border mb-0">
                          <div class="bg-gray tablehead d-flex">
                            <div class="padding-5 w-43">
                              <b>Key</b>
                            </div>
                            <div class="padding-5 w-23">
                              <b>Value</b>
                            </div>
                          </div>
                          <div className="tablerow d-flex justify-content-between">
                            <div className="highlightedtext d-flex bpfields bigfields flex-1">
                              <div className="keytd">
                                BP (systolic) /(diastolic)
                              </div>
                              <div className="valuetd">
                                <Tooltip placement="top" className="d-block">
                                  {systolic_bp}
                                </Tooltip>

                                <Tooltip
                                  placement="top"
                                  className="d-block mt-1"
                                >
                                  {diastolic_bp}
                                </Tooltip>
                              </div>
                              <div className="inputtd">
                                <div className="d-flex align-items-center two_input">
                                  <div className="input-wrapper">
                                    <InputNumber
                                      // className="custom-input-number"
                                      className={renderHealthAssessmentInputColor(
                                        latestBpValues.systolic_bp,
                                        "systolic_bp"
                                      )}
                                      min={40}
                                      max={300}
                                      type="number"
                                      value={latestBpValues.systolic_bp}
                                      onChange={(e) => {
                                        setState({
                                          ...state,
                                          systolic_bp: e,
                                        });
                                        setLatestBpValues({
                                          ...latestBpValues,
                                          systolic_bp: e,
                                        });
                                      }}
                                    />
                                    <span>/</span>
                                    <InputNumber
                                      // className="custom-input-number"
                                      className={renderHealthAssessmentInputColor(
                                        latestBpValues.diastolic_bp,
                                        "diastolic_bp"
                                      )}
                                      min={20}
                                      max={200}
                                      type="number"
                                      value={latestBpValues.diastolic_bp}
                                      onChange={(e) => {
                                        setState({
                                          ...state,
                                          diastolic_bp: e,
                                        });
                                        setLatestBpValues({
                                          ...latestBpValues,
                                          diastolic_bp: e,
                                        });
                                      }}
                                    />
                                    {/* {errors && (
                                  <span className="validate-danger font-14">
                                    {errors.diastolic_bp}
                                  </span>
                                )} */}
                                  </div>
                                  <span className="ml-1">mmHg</span>
                                </div>
                              </div>
                            </div>
                            <div className="highlightedtext d-flex">
                              <div className="keytd">Pulse</div>
                              <div className="valuetd">
                                <Tooltip placement="top">
                                  {pulse_per_minute}
                                </Tooltip>
                              </div>
                              <div className="inputtd">
                                <div className="d-flex align-items-center justify-content-end">
                                  <InputNumber
                                    // className="custom-input-number"
                                    className={renderHealthAssessmentInputColor(
                                      latestBpValues.pulse_per_minute,
                                      "pulse_per_minute"
                                    )}
                                    min={30}
                                    max={200}
                                    type="number"
                                    value={latestBpValues.pulse_per_minute}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        pulse_per_minute: e,
                                      });
                                      setLatestBpValues({
                                        ...latestBpValues,
                                        pulse_per_minute: e,
                                      });
                                    }}
                                  />

                                  <span className="ml-1">bpm</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="tablerow d-flex">
                            {/* <div className="highlightedtext d-flex">
                              <div className="keytd">Pulse</div>
                              <div className="valuetd">
                                <Tooltip placement="top">
                                  {pulse_per_minute}
                                </Tooltip>
                              </div>
                              <div className="inputtd">
                                <div className="d-flex align-items-center">
                                  <InputNumber
                                    // className="custom-input-number"
                                    className={renderHealthAssessmentInputColor(
                                      latestBpValues.pulse_per_minute,
                                      "pulse_per_minute"
                                    )}
                                    min={30}
                                    max={200}
                                    type="number"
                                    value={latestBpValues.pulse_per_minute}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        pulse_per_minute: e,
                                      });
                                      setLatestBpValues({
                                        ...latestBpValues,
                                        pulse_per_minute: e,
                                      });
                                    }}
                                  />

                                  <span className="ml-1">bpm</span>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="center-block flex flex-left justify-content-center mt-3">
                    <div className="d-flex flex-column form-group">
                        <div className="form-row mb-1">
                          <div className="col-md-12 d-flex align-items-center mb-2">
                            <label
                              htmlFor="sel1"
                              className="label-12 mb-0 mr-4"
                            >
                              Fitted with ABPM ?
                            </label>
                            <Radio.Group
                              onChange={(e) => {
                                setFittedAbpmAnswer(e.target.value);
                              }}
                              value={fittedAbpmAnswer}
                              className="inlineradiogroup d-flex"
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                        <Checkbox
                          name="office_action_needed"
                          onChange={() => {
                            setHappyToReviewSubmit(!happy_to_review_submit);
                            // setFinalStep(false);
                          }}
                          className="ml-0 mb-3"
                        >
                          Happy to review and submit
                        </Checkbox>

                        {/* <Checkbox
                          onChange={() => {
                            setIsSafetyNetting(!is_saftey_netting);
                            // setFinalStep(false);
                          }}
                        >
                          Patient has been provided safety netting as
                          recommended
                        </Checkbox> */}
                      </div>
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="sel1" className="font-12">
                              OutCome
                            </label>
                            <select
                              onChange={(e) => {
                                console.log("SSSSSS", e?.target?.value);
                                setOutComeComment(e?.target?.value);
                              }}
                              name="comment"
                              // value={comment}
                              className={`form-control height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                            >
                              <option
                                value=""
                                disabled="disabled"
                                selected="selected"
                              >
                                Select Option
                              </option>
                              <option value="Normotensive - no further follow up required">
                                Normotensive - no further follow up required
                              </option>
                              <option value="Adult >40 with no diagnosis of HTN">
                                {`Adult > 40 with no diagnosis of HTN`}
                              </option>
                              <option value="Adult <40 with FH of HTN">
                                {`Adult < 40 with FH of HTN`}
                              </option>
                              <option value="35-40 Screened High Risk">
                                35-40 Screened High Risk
                              </option>
                              <option value="Diagnosed Hypertensive (Uncontrolled ) - referred to own GP">
                                Diagnosed Hypertensive (Uncontrolled ) - referred
                                to own GP
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <TemplateDropDown
                            _handleTemplateChange={_handleTemplateChange}
                            template_id={template_id}
                            preConsultStatus={preConsultStatus}
                          />
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                  </Fragment>
                )}
                {/* {isEditMode && (
                <div className="text-left">
                  <Button
                    className="mb-3 mt-3"
                    type="primary"
                    onClick={_createPreConsultation}
                  >
                    Confirm
                  </Button>
                </div>
              )} */}
              </Fragment>
            )}
          </div>
        }
      </div>
    </div>
  );
}
